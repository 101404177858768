import React from 'react';

const getPercentage = (curr, orig) => {
  return (curr / orig) * 100;
};

export const PercentageBar = ({ ukupno, kante, kartice }) => {
  return (
    <div className="w-full">
      Odvožnje
      <span className="ml-10 text-gray-200">{kante}</span>
      <div className="w-full h-10 mb-20 rounded bg-gray-light">
        <span
          className="block h-10 mt-5 bg-gray-500 rounded"
          style={{
            width: getPercentage(kante, ukupno) + '%',
          }}
        ></span>
      </div>
      Kartica
      <span className="ml-10 text-gray-200">{kartice}</span>
      <div className="w-full h-10 rounded bg-gray-light">
        <span
          className="block h-10 mt-5 rounded bg-darkBlue"
          style={{
            width: getPercentage(kartice, ukupno) + '%',
          }}
        ></span>{' '}
      </div>
    </div>
  );
};
