import React, { useState } from 'react';
import FieldInput from '../Form/components/FieldInput';
import Button from '../../components/Button';
import Modal from '../Modal/index';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../providers/User.provider';
import Select from '../Select';
import RadioButton from '../RadioButton';
import { useParams } from 'react-router-dom';
import { capitalize, getQueryParams } from '../../utils';
import { submitNewZahtjev } from '../../services/property.service';

const volumenMjesoviti = [
  { value: '120', label: '120' },
  { value: '240', label: '240' },
];
const volumenPapirKarton = [
  { value: '120', label: '120' },
  { value: '240', label: '240' },
];

const ObjectDetailsSchema = Yup.object().shape({
  Ulica: Yup.string().required('Obavezno polje'),
  BrojClanova: Yup.string().required(),
  MkoBroj: Yup.string().required(),
  PapirBroj: Yup.string().required(),
  MkoPlaniraniBrojPredaja: Yup.string().required(),
  BioPlaniraniBrojPredaja: Yup.string().required(),
  Email: Yup.string().email().required(),
  Telefon: Yup.string().required(),
  AdresaDostaveRacuna: Yup.string().required(),
});

const ObjectDetailsForm = ({ onRequestClose, ...modalOptions }) => {
  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(ObjectDetailsSchema),
    mode: 'onChange',
  });
  const [successMessage, setSuccessMessage] = useState();

  const history = useHistory();
  const { locations: objekti } = useUser();

  const { id } = useParams();

  const objekt = objekti.find((loc) => loc.Id === id);
  const [active, setActive] = useState('DA');

  const handleChange = (id) => {
    setActive(id);
  };

  const onSubmit = async (data) => {
    try {
      await submitNewZahtjev({
        ...data,
        IdObjekta: objekt.Id,
        Komposter: active,
        PapirVolumen: data.PapirVolumen.value,
        MkoVolumen: data.PapirVolumen.value,
      });
      setSuccessMessage(true);
    } catch (e) {}
  };

  if (successMessage) {
    return (
      <div className="w-full md:w-480">
        <div className="my-40 text-lg h-200">Zahtjev uspješno poslan.</div>
        <Button
          variant="primary"
          height="55"
          width="240"
          onClick={onRequestClose}
        >
          Zatvori
        </Button>
      </div>
    );
  }

  return (
    <Modal {...modalOptions} onRequestClose={onRequestClose}>
      <form noValidate>
        <FieldInput
          labelText="Kontakt"
          register={register}
          name="Telefon"
          defaultValue=""
          type="phone"
          errors={errors}
          value={watch('Telefon')}
          className="pt-30"
        />
        <FieldInput
          labelText="Ulica"
          register={register}
          name="Ulica"
          type="text"
          errors={errors}
          value={watch('Ulica')}
        />
        <FieldInput
          labelText="Adresa dostave računa"
          register={register}
          name="AdresaDostaveRacuna"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('AdresaDostaveRacuna')}
        />
        <FieldInput
          labelText="Email"
          register={register}
          name="Email"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('Email')}
        />
        {/* <FieldInput
          labelText="Mjesto"
          register={register}
          name="Mjesto"
          type="text"
          errors={errors}
          value={watch('Mjesto')}
        />

        <Select
          labelText="Općina"
          name="Opcina"
          options={opcinaOptions}
          placeholder="/lit"
          register={register}
          control={control}
          value={watch('Opcina')}
        /> */}

        <FieldInput
          labelText="Broj članova domaćinstva"
          register={register}
          name="BrojClanova"
          defaultValue=""
          type="number"
          errors={errors}
          value={watch('BrojClanova')}
          className="w-full md:w-1/2"
        />
        <FieldInput
          labelText="Broj prijavljenih turističkih ležajeva"
          register={register}
          name="BrojLezajeva"
          defaultValue=""
          type="number"
          errors={errors}
          value={watch('BrojLezajeva')}
          className="w-full md:w-1/2"
        />

        <h2 className="w-full text-base md:w-1/2 pb-30">
          Vrsta, količina i volumen spremnika
        </h2>

        <p className="text-sm pb-25">Mješoviti otpad</p>

        <div className="flex">
          <FieldInput
            labelText="Broj komada"
            register={register}
            name="MkoBroj"
            defaultValue=""
            type="number"
            errors={errors}
            value={watch('MkoBroj')}
            className="w-1/2 md:w-1/4"
          />

          <Select
            labelText="Volumen"
            name="MkoVolumen"
            control={control}
            options={volumenMjesoviti}
            className="w-1/2 ml-20 md:w-1/4"
          />
        </div>

        <p className="text-sm pb-25">Papir i karton</p>

        <div className="flex">
          <FieldInput
            labelText="Broj komada"
            register={register}
            name="PapirBroj"
            defaultValue=""
            type="number"
            errors={errors}
            value={watch('PapirBroj')}
            className="w-1/2 md:w-1/4"
          />

          <Select
            labelText="Volumen"
            name="PapirVolumen"
            options={volumenPapirKarton}
            className="w-1/2 ml-20 md:w-1/4"
            control={control}
          />
        </div>
        <p className="text-sm pb-25">Kućna kompostana</p>

        <div className="flex justify-between w-full mb-40 md:w-1/2">
          <RadioButton
            labelText="DA"
            name="da"
            onChange={() => {
              handleChange('DA');
            }}
            checked={active === 'DA'}
          />
          <RadioButton
            labelText="NE"
            name="ne"
            onChange={() => {
              handleChange('NE');
            }}
            checked={active === 'NE'}
          />
        </div>

        <FieldInput
          labelText="Broj planiranih primopredaja miješanog komunalnog otpada"
          register={register}
          name="MkoPlaniraniBrojPredaja"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('MkoPlaniraniBrojPredaja')}
          className="w-full md:w-1/2"
        />
        <FieldInput
          labelText="Broj planiranih primopredaja biorazgradivog otpada (papir i karton, ...)"
          register={register}
          name="BioPlaniraniBrojPredaja"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('BioPlaniraniBrojPredaja')}
          className="w-full md:w-1/2"
        />
        {/* <hr className="mt-20 mb-0 border-gray-100 -ml-30 md:ml-0 md:mt-0 md:mb-40 w-mobileFull md:w-full xl:w-2/3 xl:min-w-650"></hr> */}
      </form>
      <Modal.Footer large key="ModalFooter">
        <div className="flex items-center justify-center w-full">
          <Button
            variant="primary"
            height="55"
            width="240"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Pošalji izjavu
          </Button>
          <a
            className="flex items-center justify-center text-gray-300 bg-gray-100 rounded-md ml-25 h-55 w-160"
            onClick={onRequestClose}
          >
            Poništi
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ObjectDetailsForm;
