import React from 'react';

const Checkbox = ({
  errors,
  name,
  labelText,
  subtitleText = '',
  labelComponent,
  checked = false,
  ...inputProps
}) => {
  return (
    <div className="checkbox flex py-15 justify-between items-center relative">
      {labelComponent ? (
        labelComponent
      ) : (
        <label className="block text-gray-400 text-base ml-10" htmlFor={name}>
          {labelText}
          <p className="text-xxs text-gray-300">{subtitleText}</p>
        </label>
      )}
      <div className="bg-white cursor-pointer rounded-full border-2 border-gray-200 w-32 h-32 flex flex-shrink-0 justify-center items-center  ">
        <input
          type="checkbox"
          className="opacity-0 absolute h-full w-full left-0 top-0 cursor-pointer"
          name={name}
          id={name}
          {...inputProps}
        />
        <svg
          className={` ${
            checked ? 'block' : 'hidden'
          } rounded-full min-w-32 w-32 h-32 bg-primary text-white scale-0 transition-all pointer-events-none p-8`}
          viewBox="0 0 20 20"
        >
          <path
            className="fill-white"
            fill="white"
            d="M0 11l2-2 5 5L18 3l2 2L7 18z"
          />
        </svg>
      </div>
      {/* {errors && errors[name] && (
        <p className="text-sm text-teal-800">{errors[name].message}</p>
      )} */}
    </div>
  );
};

export default Checkbox;
