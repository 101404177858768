import React, { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactSelect from 'react-select';
import { capitalize } from '../../utils';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'flex',
    height: '50px',
    width: '100% !important',
    outline: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100% !important',
    border: '0',
    borderRadius: '0',
    background: '#fff',
    color: '#2D3133',
    outline: 'none',
    border: '0',
    boxShadow: 'none',
    ':hover': {
      outline: 'none',
      border: '0',
      boxShadow: 'none',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    pointerEvents: state.isDisabled ? 'none' : 'all',
    textDecoration: state.isDisabled ? 'line-through' : 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#F2F4F4',
    borderRadius: '0',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'teal',
    ':hover': {
      color: 'teal',
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    marginLeft: '0px',
    padding: '0px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
  }),
  indicatorContainer: (provided, state) => ({
    padding: 0,
  }),
};

export const SelectChart = ({
  control,
  errors,
  name,
  options,
  className = '',
  labelText = name,
  placeholder,
  register,
  defaultValue,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      className={`${className} outline-none field-select ${
        isFocused ? 'isFocused' : ''
      }`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {control && (
        <Controller
          instanceId={name}
          as={<ReactSelect {...rest} />}
          name={name}
          options={options}
          control={control}
          defaultValue={
            defaultValue ? defaultValue : { value: '', label: 'Odaberi' }
          }
          errors={errors}
          styles={customStyles}
          ref={register}
        />
      )}
      {!control && (
        <ReactSelect
          {...rest}
          instanceId={name}
          name={name}
          options={options}
          control={control}
          defaultValue={
            defaultValue ? defaultValue : { value: '', label: 'Odaberi' }
          }
          errors={errors}
          styles={customStyles}
          className={` form-select`}
          classNamePrefix="form-select"
          placeholder={placeholder}
          ref={register}
          onChange={(value) => {
            onChange(parseInt(value.value));
          }}
        />
      )}
      {errors && errors[name] && (
        <p className="text-sm text-teal-800">{errors[name].message}</p>
      )}
    </div>
  );
};
