import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

export default function Content({ children, title, icon = null }) {
  const history = useHistory();
  const { location } = history;

  return (
    <div className="lg:w-4/6 md:w-4/6 sm:w-full p-30 md:px-60 md:pt-40 min-h-screen">
      <div className="flex items-center">
        {icon && (
          <button onClick={() => history.goBack()} className="mr-20">
            {icon}
          </button>
        )}

        <h2 className="text-md font-primaryBold">{title}</h2>
      </div>
      {location.pathname === '/' ? (
        ''
      ) : (
        <hr className="-ml-30 md:ml-0 mt-20 mb-0 md:my-40 border-gray-100 w-mobileFull md:w-full xl:w-2/3 xl:min-w-650"></hr>
      )}
      {children}
    </div>
  );
}
