import { dateFormat } from './';

function getDaysInMonthUTC(month, year) {
  var date = new Date(Date.UTC(year, month, 1));
  var days = [];
  while (date.getUTCMonth() === month) {
    days.push(new Date(date));
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return days;
}

export function SljedeciOdvoziDto(parsedData) {
  let data = [];
  if (!Array.isArray(parsedData) && parsedData !== null) {
    data = [parsedData];
  }
  if (parsedData === null) {
    data = [];
  }
  if (parsedData && Array.isArray(parsedData)) {
    data = parsedData;
  }
  const sljedeceOdvoznje = {};
  let startYear = '1970';

  let numOfYears = 0;

  data.forEach((day, i) => {
    if (startYear !== day.Datum.slice(-5, -1)) {
      numOfYears = numOfYears + 1;
      startYear = day.Datum.slice(-5, -1);
    }

    if (
      sljedeceOdvoznje[
        new Date(dateFormat(day.Datum)).toLocaleDateString('hr-HR')
      ]
    ) {
      const obj =
        sljedeceOdvoznje[
          new Date(dateFormat(day.Datum)).toLocaleDateString('hr-HR')
        ];
      sljedeceOdvoznje[
        new Date(dateFormat(day.Datum)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti:
          day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD'
            ? obj.mjesoviti + 1
            : obj.mjesoviti,
        papirKarton:
          day.VrstaOtpada === 'PAPIR I KARTON'
            ? obj.papirKarton + 1
            : obj.papirKarton,
        ambalaza:
          day.VrstaOtpada === 'AMBALAŽNI OTPAD'
            ? obj.ambalaza + 1
            : obj.ambalaza,
        staklo: day.VrstaOtpada === 'STAKLO' ? obj.staklo + 1 : obj.staklo,
      };
    } else {
      sljedeceOdvoznje[
        new Date(dateFormat(day.Datum)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti: day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' ? 1 : 0,
        papirKarton: day.VrstaOtpada === 'PAPIR I KARTON' ? 1 : 0,
        ambalaza: day.VrstaOtpada === 'AMBALAŽNI OTPAD' ? 1 : 0,
        staklo: day.VrstaOtpada === 'STAKLO' ? 1 : 0,
      };
    }
  });
  return { kalendarOdvoza: sljedeceOdvoznje, brojGodina: numOfYears };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export function OdvoziDto(parsedData, month, year) {
  let mjesovitiKanta = 0;
  let mjesovitiKartica = 0;
  let papirKartonKanta = 0;
  let papirKartonKartica = 0;
  let ambalazaKanta = 0;
  let ambalazaKartica = 0;
  let stakloKanta = 0;
  let stakloKartica = 0;

  let data = [];
  const rawData = parsedData || [];
  if (!Array.isArray(parsedData) && parsedData !== null) {
    data = [parsedData];
  }
  if (parsedData === null) {
    data = [];
  }
  if (parsedData && Array.isArray(parsedData)) {
    data = parsedData;
  }

  const odvoziKalendar = {};

  data.forEach((day, i) => {
    if (day.TipPosude === 'KARTICA') return;

    if (day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD') {
      mjesovitiKanta = mjesovitiKanta + 1;
    }

    if (day.VrstaOtpada === 'PAPIR I KARTON') {
      papirKartonKanta = papirKartonKanta + 1;
    }

    if (day.VrstaOtpada === 'AMBALAŽNI OTPAD') {
      ambalazaKanta = ambalazaKanta + 1;
    }
    if (day.VrstaOtpada === 'STAKLO') {
      stakloKanta = stakloKanta + 1;
    }
    if (
      odvoziKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ]
    ) {
      const obj =
        odvoziKalendar[
          new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
        ];
      odvoziKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti:
          day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD'
            ? obj.mjesoviti + 1
            : obj.mjesoviti,
        papirKarton:
          day.VrstaOtpada === 'PAPIR I KARTON'
            ? obj.papirKarton + 1
            : obj.papirKarton,
        ambalaza:
          day.VrstaOtpada === 'AMBALAŽNI OTPAD'
            ? obj.ambalaza + 1
            : obj.ambalaza,
        staklo: day.VrstaOtpada === 'STAKLO' ? obj.staklo + 1 : obj.staklo,
      };
    } else {
      odvoziKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti: day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' ? 1 : 0,
        papirKarton: day.VrstaOtpada === 'PAPIR I KARTON' ? 1 : 0,
        ambalaza: day.VrstaOtpada === 'AMBALAŽNI OTPAD' ? 1 : 0,
        staklo: day.VrstaOtpada === 'STAKLO' ? 1 : 0,
      };
    }
  });

  const karticeKalendar = {};

  data.forEach((day, i) => {
    if (day.TipPosude === 'SPREMNIK') return;
    if (day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD') {
      mjesovitiKartica = mjesovitiKartica + 1;
    }

    if (day.VrstaOtpada === 'PAPIR I KARTON') {
      papirKartonKartica = papirKartonKartica + 1;
    }

    if (day.VrstaOtpada === 'AMBALAŽNI OTPAD') {
      ambalazaKartica = ambalazaKartica + 1;
    }
    if (day.VrstaOtpada === 'STAKLO') {
      stakloKartica = stakloKartica + 1;
    }
    if (
      karticeKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ]
    ) {
      const obj =
        karticeKalendar[
          new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
        ];
      karticeKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti:
          day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD'
            ? obj.mjesoviti + 1
            : obj.mjesoviti,
        papirKarton:
          day.VrstaOtpada === 'PAPIR I KARTON'
            ? obj.papirKarton + 1
            : obj.papirKarton,
        ambalaza:
          day.VrstaOtpada === 'AMBALAŽNI OTPAD'
            ? obj.ambalaza + 1
            : obj.ambalaza,
        staklo: day.VrstaOtpada === 'STAKLO' ? obj.staklo + 1 : obj.staklo,
      };
    } else {
      karticeKalendar[
        new Date(dateFormat(day.DatumVrijeme)).toLocaleDateString('hr-HR')
      ] = {
        mjesoviti: day.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' ? 1 : 0,
        papirKarton: day.VrstaOtpada === 'PAPIR I KARTON' ? 1 : 0,
        ambalaza: day.VrstaOtpada === 'AMBALAŽNI OTPAD' ? 1 : 0,
        staklo: day.VrstaOtpada === 'STAKLO' ? 1 : 0,
      };
    }
  });

  const odvozi = data.filter((item) => item.TipPosude === 'SPREMNIK');
  const kartice = data.filter((item) => item.TipPosude === 'KARTICA');

  return {
    odvoziKalendar,
    karticeKalendar,
    odvozi,
    kartice,
    rawData,
    mjesovitiKanta,
    papirKartonKanta,
    mjesovitiKartica,
    papirKartonKartica,
    ambalazaKanta,
    ambalazaKartica,
    stakloKanta,
    stakloKartica,
  };
}
const dani = ['NED', 'PON', 'UTO', 'SRI', 'ČET', 'PET', 'SUB'];
const mjeseciPopis = [
  'SIJ',
  'VELJ',
  'OŽU',
  'TRA',
  'SVI',
  'LIP',
  'SRP',
  'KOL',
  'RUJ',
  'LIS',
  'STU',
  'PRO',
];
export const GodisnjiOdvoziDto = (parsedData) => {
  let mjesovitiKanta = 0;
  let mjesovitiKartica = 0;
  let papirKartonKanta = 0;
  let papirKartonKartica = 0;
  let ambalazaKanta = 0;
  let ambalazaKartica = 0;
  let stakloKanta = 0;
  let stakloKartica = 0;

  let data = [];
  if (!Array.isArray(parsedData) && parsedData !== null) {
    data = [parsedData];
  }
  if (parsedData === null) {
    data = [];
  }
  if (parsedData && Array.isArray(parsedData)) {
    data = parsedData;
  }
  const podaci = data.map((r) => {
    if (r.TipPosude === 'SPREMNIK') {
      if (r.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD')
        mjesovitiKanta = mjesovitiKanta + 1;
      if (r.VrstaOtpada === 'PAPIR I KARTON')
        papirKartonKanta = papirKartonKanta + 1;
      if (r.VrstaOtpada === 'AMBALAŽNI OTPAD')
        ambalazaKanta = ambalazaKanta + 1;
      if (r.VrstaOtpada === 'STAKLO') stakloKanta = stakloKanta + 1;
    } else {
      if (r.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD')
        mjesovitiKartica = mjesovitiKartica + 1;
      if (r.VrstaOtpada === 'PAPIR I KARTON')
        papirKartonKartica = papirKartonKartica + 1;
      if (r.VrstaOtpada === 'AMBALAŽNI OTPAD')
        ambalazaKartica = ambalazaKartica + 1;
      if (r.VrstaOtpada === 'STAKLO') stakloKartica = stakloKartica + 1;
    }

    return {
      mjesec: new Date(dateFormat(r.DatumVrijeme)).toLocaleDateString('hr-HR', {
        month: 'short',
      }),
      mjesovitiKanta:
        r.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' &&
        r.TipPosude === 'SPREMNIK'
          ? 1
          : 0,
      mjesovitiKartica:
        r.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' &&
        r.TipPosude === 'KARTICA'
          ? 1
          : 0,
      papirKartonKanta:
        r.VrstaOtpada === 'PAPIR I KARTON' && r.TipPosude === 'SPREMNIK'
          ? 1
          : 0,
      papirKartonKartica:
        r.VrstaOtpada === 'PAPIR I KARTON' && r.TipPosude === 'KARTICA' ? 1 : 0,
      ambalazaKanta:
        r.VrstaOtpada === 'AMBALAŽNI OTPAD' && r.TipPosude === 'SPREMNIK'
          ? 1
          : 0,
      ambalazaKartica:
        r.VrstaOtpada === 'AMBALAŽNI OTPAD' && r.TipPosude === 'KARTICA'
          ? 1
          : 0,
      stakloKanta:
        r.VrstaOtpada === 'STAKLO' && r.TipPosude === 'SPREMNIK' ? 1 : 0,
      stakloKartica:
        r.VrstaOtpada === 'STAKLO' && r.TipPosude === 'KARTICA' ? 1 : 0,
      mjFull: new Date(dateFormat(r.DatumVrijeme)).toLocaleDateString('hr-HR', {
        month: 'long',
      }),
      tip: r.TipPosude,
    };
  });
  const mjeseci = {};
  podaci.forEach((zapis) => {
    const prev = mjeseci[zapis.mjesec];
    mjeseci[zapis.mjesec] = prev
      ? {
          ...prev,
          mjesovitiKanta: prev.mjesovitiKanta + zapis.mjesovitiKanta,
          mjesovitiKartica: prev.mjesovitiKartica + zapis.mjesovitiKartica,
          papirKartonKanta: prev.papirKartonKanta + zapis.papirKartonKanta,
          papirKartonKartica:
            prev.papirKartonKartica + zapis.papirKartonKartica,
          ambalazaKanta: prev.ambalazaKanta + zapis.ambalazaKanta,
          ambalazaKartica: prev.ambalazaKartica + zapis.ambalazaKartica,
          stakloKanta: prev.stakloKanta + zapis.stakloKanta,
          stakloKartica: prev.stakloKartica + zapis.stakloKartica,
        }
      : zapis;
  });
  const parsiraniMjeseci = Object.keys(mjeseci).map((key) => {
    return {
      mjesec: key.toUpperCase(),
      mjFull: mjeseci[key].mjFull,
      mjesovitiKanta: mjeseci[key].mjesovitiKanta,
      mjesovitiKartica: mjeseci[key].mjesovitiKartica,
      papirKartonKanta: mjeseci[key].papirKartonKanta,
      papirKartonKartica: mjeseci[key].papirKartonKartica,
      ambalazaKanta: mjeseci[key].ambalazaKanta,
      ambalazaKartica: mjeseci[key].ambalazaKartica,
      stakloKanta: mjeseci[key].stakloKanta,
      stakloKartica: mjeseci[key].stakloKartica,
      mjIndex:
        mjeseciPopis.findIndex(
          (mj) => mj.toLowerCase() === mjeseci[key].mjesec
        ) + 1,
    };
  });
  return {
    mjesovitiKanta,
    mjesovitiKartica,
    papirKartonKanta,
    papirKartonKartica,
    ambalazaKanta,
    ambalazaKartica,
    stakloKanta,
    stakloKartica,
    odvoziPoMjesecu: mjeseciPopis.map((mjesec) => {
      const current = parsiraniMjeseci.find((m) => m.mjesec === mjesec);
      if (current) return current;
      return {
        mjFull: '',
        mjesec: mjesec,
        mjesovitiKanta: 0,
        mjesovitiKartica: 0,
        papirKartonKanta: 0,
        papirKartonKartica: 0,
        ambalazaKanta: 0,
        ambalazaKartica: 0,
        stakloKanta: 0,
        stakloKartica: 0,
        mjIndex: mjeseciPopis.findIndex((mj) => mj === mjesec) + 1,
      };
    }),
  };
};
