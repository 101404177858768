export const getYearOptions = (year) => {
  let yearOptions = [];

  for (let i = 2018; i <= year; i++) {
    yearOptions.push({
      value: String(i),
      label: String(i),
    });
  }

  return yearOptions;
};
