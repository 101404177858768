import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  login,
  requestAccount,
  confirmAccount,
} from '../services/auth.service';
import { getObjekti } from '../services/user.service';
import { useToast } from '../hooks/useToast';

export const UserContext = createContext({});

export const useUser = () => useContext(UserContext);

export const getUser = () => {
  const user = localStorage.getItem('user');
  return JSON.parse(user) || undefined;
};

export const getLocation = () => {
  const location = localStorage.getItem('selectedLocation');
  return JSON.parse(location) || undefined;
};

export const getLocations = () => {
  const locations = localStorage.getItem('locations');
  return JSON.parse(locations) || undefined;
};
function UserProvider({ children }) {
  const [user, setUser] = useState(() => getUser());
  const [locations, setLocations] = useState(() => getLocations());
  const [selectedLocation, setSelectedLocation] = useState(() => getLocation());
  const show = useToast();

  const context = useMemo(() => {
    return {
      user,
      locations,
      selectedLocation,
      handleLogin: async (oib, password) => {
        try {
          const res = await login(oib, password);
          const user = await JSON.parse(res);
          setUser(user.Korisnik);
          localStorage.setItem('user', JSON.stringify(user.Korisnik));
        } catch (error) {
          show({
            headerText: 'Greška',
            message: 'Pogreška prilikom prijave. Pokušajte ponovno.',
            type: 'error',
          });
          return Promise.reject('Login error', error);
        }
      },

      handleLogout: async () => {
        try {
          setUser(undefined);
          localStorage.removeItem('user');
          localStorage.removeItem('locations');
          localStorage.removeItem('selectedLocation');
        } catch (error) {
          return Promise.reject('Logout Failed');
        }
      },

      handleRequestAccount: async (oib, email) => {
        try {
          const createdUser = await requestAccount(oib, email);
          if (createdUser) {
            const confirmedUser = await confirmAccount(
              createdUser.Korisnik.token
            );
            setUser(confirmedUser.Korisnik);
          }
        } catch (error) {
          Promise.reject('Request Account Failed');
        }
      },
      handleResetPassword: async (username) => {
        try {
          await forgotPassword(username);
        } catch (error) {
          Promise.reject('Reset Password Failed');
        }
      },
      handleSelectLocation: (id) => {
        const selected = locations.find((loc) => loc.Id === id);
        setSelectedLocation(selected);
        localStorage.setItem('selectedLocation', JSON.stringify(selected));
      },
    };
  }, [user, selectedLocation, locations]);

  useEffect(() => {
    if (user && !locations) {
      getObjekti().then((res) => {
        localStorage.setItem('locations', JSON.stringify(res));
        setLocations(res);
      });
    }
    if (!selectedLocation && locations) {
      localStorage.setItem('selectedLocation', JSON.stringify(locations[0]));
      setSelectedLocation(locations[0]);
    }
  }, [user, locations]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

export default UserProvider;
