import React, { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactSelect from 'react-select';
import { capitalize } from '../../utils';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'flex',
    height: '50px',
    width: '100% !important',
    outline: 'none',
    borderBottom: state.isFocused ? '0' : '2px solid #C5C9CB',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100% !important',
    border: '0',
    borderRadius: '0',
    background: '#F2F4F4',
    color: '#2D3133',
    paddingLeft: '10px',
    outline: 'none',
    border: state.isFocused ? '2px solid #2D3133' : '0',
    boxShadow: 'none',
    ':active': {
      border: state.isFocused ? '2px solid #2D3133' : '0',
    },
    ':hover': {
      outline: 'none',
      border: state.isFocused ? '2px solid #2D3133' : '0',
      boxShadow: 'none',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    pointerEvents: state.isDisabled ? 'none' : 'all',
    textDecoration: state.isDisabled ? 'line-through' : 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#F2F4F4',
    borderRadius: '0',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
};

const Select = ({
  control,
  errors,
  name,
  options,
  className = '',
  showLabel = true,
  labelText = name,
  placeholder,
  register,
  defaultValue,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      className={`${className} mb-25 outline-none field-select ${
        isFocused ? 'isFocused' : ''
      }`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {showLabel && (
        <label className="block mb-5 text-gray-300 text-xxs" htmlFor={name}>
          {capitalize(labelText)}
        </label>
      )}
      {control && (
        <Controller
          instanceId={name}
          as={<ReactSelect {...rest} />}
          name={name}
          options={options}
          control={control}
          defaultValue={
            defaultValue ? defaultValue : { value: '', label: 'Odaberi' }
          }
          errors={errors}
          styles={customStyles}
          ref={register}
        />
      )}
      {!control && (
        <ReactSelect
          {...rest}
          instanceId={name}
          name={name}
          options={options}
          control={control}
          defaultValue={
            defaultValue ? defaultValue : { value: '', label: 'Odaberi' }
          }
          errors={errors}
          styles={customStyles}
          className={` form-select`}
          placeholder={placeholder}
          ref={register}
          onChange={(value) => {
            onChange(parseInt(value.value));
          }}
        />
      )}
      {errors && errors[name] && (
        <p className="text-sm text-teal-800">{errors[name].message}</p>
      )}
    </div>
  );
};
export default Select;
