import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg';

export const Header = ({ className, headerText, onRequestClose }) => {
  return (
    <div
      className={`${
        className || ''
      } z-99 relative pt-40 flex justify-between items-center border-b-2 border-gray-100 px-30 md:px-60 pb-30`}
    >
      <h1 className="text-gray-500 text-md">{headerText}</h1>
      <a onClick={onRequestClose}>
        <CloseIcon
          fill="gray"
          className="absolute top-50px right-30 md:right-60"
        ></CloseIcon>
      </a>
    </div>
  );
};
