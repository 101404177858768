import * as React from 'react';

export default class YAxis extends React.PureComponent {
  render() {
    const { y1, y2 } = this.props;
    return (
      <div className="sticky z-10 bg-white max-h-150 -left-10 ">
        <div className="relative flex flex-col justify-between h-full bg-white border-gray-200 border-solid max-h-130 border-r-1 w-30 -bottom-5 ">
          {y2 !== 0 && (
            <>
              <p className="pr-5 text-right text-gray-200 md:pr-0 md:text-center text-xxxs">
                {y2}
              </p>
              <p className="pr-5 text-right text-gray-200 md:pr-0 md:text-center text-xxxs">
                {Math.ceil(y1)}
              </p>{' '}
            </>
          )}
          <p
            className={`pr-5 ${
              y2 !== 0 ? '' : 'mt-auto'
            } text-right text-gray-200 md:pr-0 md:text-center text-xxxs`}
          >
            0
          </p>
        </div>
      </div>
    );
  }
}
