import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '../../providers/User.provider';

import LoginForm from '../../components/LoginForm';
import AuthLayout from '../../components/commons/AuthLayout';

const Login = () => {
  return (
    <AuthLayout title="Prijavi se">
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
