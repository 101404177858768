export const getDatePreviousMonth = (date) => {
  const newDate = new Date(date);

  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  newDate.setDate(1);

  if (dateMonth === 0) {
    newDate.setMonth(11);
    newDate.setYear(dateYear - 1);
  } else {
    newDate.setMonth(dateMonth - 1);
  }
  return newDate;
};
