import { dateFormat } from './';

export const parseDate = (dateAndTime) => {
  const date = new Date(dateFormat(dateAndTime));
  return {
    date: date.toLocaleDateString('hr-HR', {
      weekday: 'long',
    }),
    fullDate: date.toLocaleDateString('hr-HR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    time: date.toLocaleTimeString('hr-HR', {
      hour: '2-digit',
      minute: '2-digit',
    }),
  };
};
