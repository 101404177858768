import { dateFormat } from './';

export const reciklaznoDvoristeDto = (data, mj, god) => {
  if (data.ReciklaznoDvoriste) {
    let dataArray;
    if (Array.isArray(data.ReciklaznoDvoriste.PredaniOtpad)) {
      dataArray = data.ReciklaznoDvoriste.PredaniOtpad;
    } else {
      dataArray = [data.ReciklaznoDvoriste.PredaniOtpad];
    }
    const newData = dataArray.filter(
      (item) =>
        new Date(dateFormat(item.DatumVrijeme)).getMonth() + 1 ===
          parseInt(mj) &&
        new Date(dateFormat(item.DatumVrijeme)).getFullYear() === parseInt(god)
    );
    return newData;
  }
  return [];
};
