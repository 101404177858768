import React, { useState } from 'react';

const FieldInput = ({
  register,
  errors,
  name,
  type = 'text',
  labelText = name,
  touched = false,
  value,
  className,
  ...inputProps
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const toggleIsHidden = () => setIsHidden(!isHidden);
  return (
    <div
      className={`${className} mb-25 flex flex-col 
			
				${errors && errors[name] ? 'isInvalid' : ''} 
			`}
    >
      <label
        className={`block text-xxs mb-5 ${
          errors && name && errors[name] ? 'text-red' : 'text-gray-300 '
        } `}
        htmlFor={name}
      >
        {labelText}
      </label>
      <div
        className={` flex w-full focus:shadow-outline h-50 relative placeholder-secondary
  
        `}
      >
        <input
          className={`flex items-center w-full h-full text-xs px-20 h-full ${
            errors && name && errors[name]
              ? 'border-b-2 border-red bg-red bg-opacity-8'
              : 'border-b-2 border-gray-200 bg-gray-100'
          }  outline-none ${isFocused ? 'border-2 border-gray-500' : ''} `}
          id={name}
          type={type === 'password' ? (isHidden && 'password') || 'text' : type}
          name={name}
          ref={register}
          defaultValue={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...inputProps}
        />
        {type === 'password' && value && value !== '' && (
          <button
            onClick={toggleIsHidden}
            type="button"
            className="bg-transparent text-gray-300 absolute right-0 h-full text-xxs border-none outline-none mr-20 ml-20"
          >
            {isHidden ? 'Prikaži' : 'Sakrij'}
          </button>
        )}
        {name === 'phoneNumber' && (
          <p
            className="flex text-success text-xs font-bold absolute l-0"
            style={{ bottom: '-75px' }}
          >
            Unesi točan broj mobitela!
          </p>
        )}
      </div>
      {errors && name && errors[name] && (
        <p
          data-testid={`${name}-input-error`}
          className="text-xs mt-5 text-red text-right"
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default FieldInput;
