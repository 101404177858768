import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FieldInput from '../Form/components/FieldInput';
import styles from './LoginForm.module.scss';
import Button from '../Button';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../providers/User.provider';

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Obavezno polje'),
  lozinka: Yup.string()
    .min(8, 'Unesi lozinku od minimalno 8 znakova')
    .required('Obavezno polje'),
});

const LoginForm = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: 'onChange',
  });
  const history = useHistory();
  const { handleLogin } = useUser();

  const onSubmit = async (data) => {
    try {
      await handleLogin(data.username, data.lozinka);
      history.push('/');
    } catch (err) {}
  };

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full sm:mb-50 md:mb-0 md:w-480"
      >
        <FieldInput
          register={register}
          name="username"
          defaultValue=""
          type="text"
          errors={errors}
          labelText="Korisničko ime"
          value={watch('username')}
        />
        <FieldInput
          register={register}
          name="lozinka"
          defaultValue=""
          type="password"
          errors={errors}
          labelText="Lozinka"
          value={watch('lozinka')}
        />
        <div className={`${styles.ButtonWrapper} mt-20 sm:mb-30 md:mb-0`}>
          <Button
            variant="primary"
            height="55"
            width="240"
            isLoading={isSubmitting}
          >
            Prijavi se
          </Button>
        </div>
      </form>
      <div className="static flex justify-between text-xs text-gray-200 font-secondary bottom-40 mt-70 md:hidden">
        <span>Peovica d.o.o 2020.</span>
        <span>MikroGoran + Locastic</span>
      </div>
    </>
  );
};

export default LoginForm;
