import React, { useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar-icon.svg';
import Loader from '../../../../components/Loader';
import { ReactComponent as PdfIcon } from '../../../../assets/icons/pdf-icon.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-icon.svg';
import { Router, useHistory } from 'react-router-dom';
import useWindowSize from '../../../../hooks/useWindowSize';
import { AnimatePresence, motion } from 'framer-motion';
import { getRacunBase64 } from '../../../../services/property.service';
import { removeLeadingZerrow } from '../../../../utils';

const mjeseci = [
  'Siječanj',
  'Veljača',
  'Ožujak',
  'Travanj',
  'Svibanj',
  'Lipanj',
  'Srpanj',
  'Kolovoz',
  'Rujan',
  'Listopad',
  'Studeni',
  'Prosinac',
];

export default function Listing({ data, initialExpanded = false, title }) {
  const [expanded, setExpanded] = useState(initialExpanded);
  const history = useHistory();
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handlePDFDownload = async (id, datum) => {
    const mjesec = removeLeadingZerrow(datum.slice(3, 5));
    const godina = datum.slice(6, -1);

    const res = await getRacunBase64(id);
    let a = document.createElement('a');
    a.href = `data:application/pdf;base64,${res || ''}`;
    a.download = `racun_${mjeseci[mjesec - 1]}_${godina}.pdf`;
    a.click();
  };

  const isMobile = useWindowSize('(max-width: 950px)');
  return (
    <div className="w-mobileFull md:w-full -ml-30 md:ml-0 xl:w-2/3 md:mt-55 md:rounded-lg xl:min-w-650">
      <div className="flex items-center w-full mb-0 md:mb-20">
        <button
          onClick={toggleExpanded}
          className={`flex items-center w-full py-20 border-b-1 border-gray-100 outline-none px-${
            isMobile ? '20' : '0'
          } `}
        >
          <h1 className="pr-5 text-base font-primaryBold">{title}</h1>
          <ArrowDown
            fill="#43B0A4"
            className={`${expanded && 'transform rotate-180'}`}
          />
        </button>
      </div>
      <AnimatePresence>
        {!isMobile &&
          expanded &&
          (!data ? (
            <Loader color="primary" center />
          ) : (
            <motion.table
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="w-full text-sm font-bold table-fixed min-w-650 mb-50"
            >
              <React.Fragment>
                <thead>
                  <tr className="text-left text-gray-300 text-xxs">
                    <th className="">Mjesec</th>
                    <th className="">Datum računa</th>
                    <th className="">Status</th>
                    <th className="">Cijena</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    <p className="py-20 min-w-sidebar">Nema podataka</p>
                  ) : (
                    data.map((mjesec, index) => {
                      const godina = mjesec.Datum.slice(6, -1);
                      return (
                        <tr
                          className="text-xs border-gray-100 border-b-1"
                          key={mjesec.Id}
                        >
                          <td className="py-20">
                            {
                              mjeseci[
                                removeLeadingZerrow(mjesec.Datum.slice(3, 5)) -
                                  1
                              ]
                            }
                          </td>
                          <td className="py-20 text-gray-400">
                            {mjesec.Datum}
                          </td>
                          <td className="py-20 text-gray-400">
                            {mjesec.StatusPlacanja === 'true'
                              ? 'Plaćeno'
                              : 'Neplaćeno'}
                          </td>
                          <td className="py-20">{mjesec.Iznos} {godina <2023 ? 'Kn' : '€'}</td>
                          <td>
                            <button
                              onClick={() =>
                                handlePDFDownload(mjesec.Id, mjesec.Datum)
                              }
                            >
                              <PdfIcon />
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                history.push(
                                  `/racuni/${mjesec.Id}?status=${mjesec.StatusPlacanja}`
                                )
                              }
                            >
                              <ArrowDown
                                fill="#C5C9CB"
                                className="transform -rotate-90"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </React.Fragment>
            </motion.table>
          ))}
      </AnimatePresence>

      {isMobile && expanded && (
        <div className="px-20">
          {data &&
            data.map((mjesec, index) => {
              const godina = mjesec.Datum.slice(6, -1);
              return (
                <div
                  className="flex items-center py-20 text-xs"
                  key={mjesec.Id}
                >
                  <div className="w-2/5">
                    <h2 className="">
                      {
                        mjeseci[
                          removeLeadingZerrow(mjesec.Datum.slice(3, 5)) - 1
                        ]
                      }
                    </h2>
                    <p className="text-gray-400 ">{mjesec.Datum}</p>
                  </div>

                  <div className="w-2/5 text-left">
                    <h2 className="">{mjesec.Iznos} {godina <2023 ? 'Kn' : '€'}</h2>

                    <p className="text-gray-400 uppercase ">
                      {mjesec.StatusPlacanja === 'true'
                        ? 'Plaćeno'
                        : 'Neplaćeno'}
                    </p>
                  </div>

                  <div className="w-1/5 text-right">
                    <button
                      onClick={() =>
                        history.push(
                          `/racuni/${mjesec.Id}?status=${mjesec.StatusPlacanja}`
                        )
                      }
                    >
                      <ArrowDown
                        fill="#C5C9CB"
                        className="transform -rotate-90"
                      />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
