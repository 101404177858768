import React, { useEffect, useMemo } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/index';

import { ReactComponent as InvoiceIcon } from '../../assets/icons/receipt-icon.svg';
import { ReactComponent as BarChartIcon } from '../../assets/icons/bar-chart-icon.svg';
import { NavLink } from 'react-router-dom';
import bwip from 'bwip-js';

import { TIP_OTPADA } from '../../constants';

const getPercentage = (curr, orig) => {
  return (curr / orig) * 100;
};

const MonthDetails = ({
  year,
  data,
  onRequestClose,
  type = 'KANTA',
  ...modalOptions
}) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const getIsCurrentMonth = (month) => {
    return currentMonth + 1 === month && currentYear === year;
  };

  useEffect(() => {
    try {
      if (data && data.racun && !getIsCurrentMonth(data.mjIndex)) {
        setTimeout(() => {
          bwip.toCanvas('invoiceBarcode', {
            bcid: 'pdf417', // Barcode type
            text: data.racun.Barkod, // Text to encode
            scale: 3, // 3x scaling factor
            height: 10, // Bar height, in millimeters
            width: 7,
            includetext: false, // Show human-readable text
            textxalign: 'center', // Always good to set this
          });
        }, 50);
      }
    } catch (error) {}
  }, [data, bwip]);
  if (!data) {
    return <></>;
  }

  const ukupnoKanta =
    data.mjesovitiKanta +
    data.papirKartonKanta +
    data.ambalazaKanta +
    data.stakloKanta;

  const ukupnoKartica =
    data.mjesovitiKartica +
    data.papirKartonKartica +
    data.ambalazaKartica +
    data.stakloKartica;

  return (
    <Modal {...modalOptions} onRequestClose={onRequestClose}>
      <div className="flex flex-col">
        <div className="flex items-center py-30">
          <h2 className="flex items-center text-base text-gray-400">
            <BarChartIcon fill="#757F83" className="mr-20" /> Broj odvoza
          </h2>
          {type === 'KANTA' ? (
            <p className="pl-10 text-base text-gray-200">{ukupnoKanta}</p>
          ) : (
            <p className="pl-10 text-base text-gray-200">{ukupnoKartica}</p>
          )}
        </div>
        <div className="pb-20">
          Mješoviti otpad{' '}
          {type === 'KANTA' ? data.mjesovitiKanta : data.mjesovitiKartica}
          <div className="w-full h-10 rounded bg-gray-light">
            {type === 'KANTA' ? (
              <span
                className={`block h-10 mt-5 bg-${TIP_OTPADA.mjesoviti.color} rounded`}
                style={{
                  width: getPercentage(data.mjesovitiKanta, ukupnoKanta) + '%',
                }}
              ></span>
            ) : (
              <span
                className={`block h-10 mt-5 bg-${TIP_OTPADA.mjesoviti.color} rounded`}
                style={{
                  width:
                    getPercentage(data.mjesovitiKartica, ukupnoKartica) + '%',
                }}
              ></span>
            )}
          </div>
        </div>
        {type === 'KANTA' && (
          <div className="pb-20">
            Papir i karton {data.papirKartonKanta}
            <div className="w-full h-10 rounded bg-gray-light">
              <span
                className={`block h-10 mt-5 rounded bg-${TIP_OTPADA.papirKarton.color}`}
                style={{
                  width:
                    getPercentage(data.papirKartonKanta, ukupnoKanta) + '%',
                }}
              ></span>
            </div>
          </div>
        )}
        <div className="pb-20">
          Ambalažni otpad{' '}
          {type === 'KANTA' ? data.ambalazaKanta : data.ambalazaKartica}
          <div className="w-full h-10 rounded bg-gray-light">
            {type === 'KANTA' ? (
              <span
                className={`block h-10 mt-5 rounded bg-${TIP_OTPADA.ambalazni.color}`}
                style={{
                  width: getPercentage(data.ambalazaKanta, ukupnoKanta) + '%',
                }}
              ></span>
            ) : (
              <span
                className={`block h-10 mt-5 rounded bg-${TIP_OTPADA.ambalazni.color}`}
                style={{
                  width:
                    getPercentage(data.ambalazaKartica, ukupnoKartica) + '%',
                }}
              ></span>
            )}
          </div>
        </div>
        <div className="pb-20">
          Stakleni otpad{' '}
          {type === 'KANTA' ? data.stakloKanta : data.stakloKartica}
          <div className="w-full h-10 rounded bg-gray-light">
            {type === 'KANTA' ? (
              <span
                className={`block h-10 mt-5 bg-${TIP_OTPADA.stakleni.color} rounded`}
                style={{
                  width: getPercentage(data.stakloKanta, ukupnoKanta) + '%',
                }}
              ></span>
            ) : (
              <span
                className={`block h-10 mt-5 bg-${TIP_OTPADA.stakleni.color} rounded`}
                style={{
                  width:
                    getPercentage(data.ambalazaKartica, ukupnoKartica) + '%',
                }}
              ></span>
            )}
          </div>
        </div>
        {!getIsCurrentMonth(data.mjIndex) && data.racun && (
          <>
            <div className="w-screen h-2 bg-gray-100 md:w-380 md:w-480 -ml-30 md:-ml-60"></div>

            <div className="py-40">
              <h2 className="flex items-center text-base text-gray-400">
                <InvoiceIcon fill="#757F83" className="mr-20" /> Iznos računa
              </h2>

              <div className="flex pt-30">
                <div>
                  <h1 className="pb-10 text-gray-500 text-price">
                    {data.racun && data.racun.Iznos}
                    <span className="text-md">{year <2023 ? 'kn' : '€'}</span>
                  </h1>
                  {data.racun.StatusPlacanja ? (
                    <span className="inline px-10 py-5 text-center text-white bg-green-500 rounded-xs">
                      PLAĆENO
                    </span>
                  ) : (
                    <span className="inline px-10 py-5 text-center text-white bg-red rounded-xs">
                      NEPLAĆENO
                    </span>
                  )}
                </div>

                <div className="ml-auto">
                  <canvas width={0} height={0} id="invoiceBarcode"></canvas>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal.Footer key="ModalFooter">
        <NavLink
          to={`${
            !getIsCurrentMonth(data.mjIndex) &&
            data.racun &&
            `/racuni/${data.racun.Id}?status=${data.racun.StatusPlacanja}`
          }`}
        >
          {/* <Button
            type="button"
            variant="primary"
            disabled={getIsCurrentMonth(data.mjIndex) || !data.racun}
            className="mb-20"
          >
            Plati online
          </Button> */}
        </NavLink>
        <Button type="button" onClick={onRequestClose} variant="">
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MonthDetails;
