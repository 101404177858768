import React from 'react';

export default function Status({ status }) {
  if (status === 'Odobren') {
    return (
      <span className="inline px-10 py-5 mt-20 text-white rounded-sm bg-darkBlue">
        ODOBREN
      </span>
    );
  }

  return (
    <span className="inline px-10 py-5 mt-20 text-black rounded-sm bg-darkYellow">
      U OBRADI
    </span>
  );
}
