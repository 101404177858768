import React from 'react';

const adjustDate = (date) => {
  return date.split('.').join('. ').trim();
};

export const CalendarDots = ({ date, obj }) => {
  if (!obj) return <></>;

  const dayData = React.useMemo(() => {
    return obj[date.toLocaleDateString('HR-hr')];
  }, [obj]);

  if (!dayData) return <></>;

  return (
    <div className="flex mt-2">
      {dayData.mjesoviti > 0 && (
        <div className="w-8 h-8 mr-2 bg-gray-400 rounded-full last:mr-0 "></div>
      )}
      {dayData.papirKarton > 0 && (
        <div className="w-8 h-8 mr-2 rounded-full last:mr-0 bg-darkBlue "></div>
      )}
      {dayData.ambalaza > 0 && (
        <div className="w-8 h-8 mr-2 rounded-full last:mr-0 bg-darkYellow "></div>
      )}
      {dayData.staklo > 0 && (
        <div className="w-8 h-8 mr-2 bg-green-400 rounded-full last:mr-0 "></div>
      )}
    </div>
  );
};
