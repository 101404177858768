import { useScrollLock } from '../../hooks/useScrollLock';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export const CustomScrollbars = ({ children, autoHeightMax }) => {
  const ref = React.createRef();
  useScrollLock(true, ref);

  const handleScrollbarsMount = (scrollbars) => {
    if (scrollbars) {
      const viewElm = scrollbars.view;
      ref.current = viewElm;
    }
  };
  return (
    <Scrollbars
      ref={(scroll) => {
        handleScrollbarsMount(scroll);
      }}
      autoHide
      autoHeight
      hideTracksWhenNotNeeded
      autoHeightMax={autoHeightMax || '80vh'}
      renderTrackVertical={(props) => (
        <div
          {...props}
          className="inset-0 left-auto w-1 mt-10 mb-10 mr-10 bg-gray-100 rounded-lg track-vertical"
        />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="bg-gray-200 rounded thumb-vertical" />
      )}
      renderView={(props) => {
        return <div {...props} className="view" />;
      }}
    >
      {children}
    </Scrollbars>
  );
};
