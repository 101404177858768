import React from 'react';
import { TIP_OTPADA } from '../../../../constants.js';

const Legend = ({
  className,
  type = 'KANTA',
  showNum,
  mjes,
  pik,
  amb,
  sta,
}) => {
  return (
    <div className={`${className ? className : ''} flex flex-col `}>
      <p
        key={TIP_OTPADA.mjesoviti.name}
        className="relative text-gray-300 text-xxxs"
      >
        {TIP_OTPADA.mjesoviti.name} {showNum && `: ${mjes}`}
        <span
          className={`w-8 h-8 block rounded-full absolute -left-20 top-4 bg-${TIP_OTPADA.mjesoviti.color} `}
        ></span>
      </p>
      {type !== 'KARTICE' && (
        <p
          key={TIP_OTPADA.papirKarton.name}
          className="relative text-gray-300 text-xxxs"
        >
          {TIP_OTPADA.papirKarton.name} {showNum && `: ${pik}`}
          <span
            className={`w-8 h-8 block rounded-full absolute -left-20 top-4 bg-${TIP_OTPADA.papirKarton.color} `}
          ></span>
        </p>
      )}
      <p
        key={TIP_OTPADA.ambalazni.name}
        className="relative text-gray-300 text-xxxs"
      >
        {TIP_OTPADA.ambalazni.name} {showNum && `: ${amb}`}
        <span
          className={`w-8 h-8 block rounded-full absolute -left-20 top-5 bg-${TIP_OTPADA.ambalazni.color} `}
        ></span>
      </p>
      <p
        key={TIP_OTPADA.stakleni.name}
        className="relative text-gray-300 text-xxxs"
      >
        {TIP_OTPADA.stakleni.name} {showNum && `: ${sta}`}
        <span
          className={`w-8 h-8 block rounded-full absolute -left-20 top-5 bg-${TIP_OTPADA.stakleni.color} `}
        ></span>
      </p>
    </div>
  );
};

export default Legend;
