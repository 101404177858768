import React, { useCallback, useMemo } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getMjesecnaStatistika,
  getOdvozi,
  getRacunDetails,
} from '../../services/property.service';
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';
import useWindowSize from '../../hooks/useWindowSize';

import { dateFormat } from '../../utils';

const transformDataForListing = (odvozi) => {
  return odvozi.map((odvoz) => ({
    tip:
      odvoz.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD'
        ? 'Mješoviti otpad'
        : odvoz.VrstaOtpada === 'PAPIR I KARTON'
        ? 'Papir i karton'
        : 'Ambalažni otpad',
    datum: new Date(dateFormat(odvoz.DatumVrijeme)).toLocaleDateString('hr-HR'),
    tipPosude: odvoz.TipPosude === 'SPREMNIK' ? 'Kanta' : 'Kartica',
    vrijeme: `${new Date(dateFormat(odvoz.DatumVrijeme)).toLocaleTimeString(
      'hr-HR',
      {
        hour: '2-digit',
        minute: '2-digit',
      }
    )} sati`,
    volumen: odvoz.UdioPosude,
  }));
};

export default function Odvozi() {
  const { id } = useParams();
  const isMobile = useWindowSize('(max-width: 890px)');

  const { data: racun } = useQuery([`racun_detalji_${id}`], () => {
    return getRacunDetails(id);
  });
  const mjesec = useMemo(() => {
    if (racun) {
      return parseInt(racun.DatumDokumenta.split('.')[1], 10);
    }
    return '';
  }, [racun]);

  const godina = useMemo(() => {
    if (racun) {
      return parseInt(racun.DatumDokumenta.split('.')[2], 10);
    }
    return '';
  }, [racun]);

  const handleGetOdvozi = async () => {
    if (mjesec && godina) {
      return await getMjesecnaStatistika({ year: godina, month: mjesec });
    }
  };
  const { data: odvozi } = useQuery(
    racun && `odvozi_racun_${id}`,
    handleGetOdvozi
  );

  const transformedData = useMemo(() => {
    if (odvozi) {
      return transformDataForListing(odvozi.rawData);
    }
    return [];
  }, [odvozi]);
  return (
    <LayoutComponent
      title={`Odvozi i korištenje kartice za ${mjesec}. mjesec`}
      icon={<BackIcon fill="#C5C9CB" />}
    >
      {isMobile ? (
        <div className="flex flex-col justify-between">
          {transformedData.map((item) => (
            <div
              className="flex justify-between py-20 text-xs border-gray-100"
              key={`${item.datum}-${item.vrijeme}`}
            >
              <div className="flex items-center">
                <div
                  className={`${
                    item.tip === 'Mješoviti otpad'
                      ? 'bg-black'
                      : item.tip === 'Papir i karton'
                      ? 'bg-darkBlue'
                      : 'bg-darkYellow'
                  } w-18 h-18 rounded-full mr-25`}
                ></div>
                <p
                  className={`${
                    item.tip === 'Mješoviti otpad'
                      ? 'text-black'
                      : item.tip === 'Papir i karton'
                      ? 'text-darkBlue'
                      : 'text-darkYellow'
                  } text-sm`}
                >
                  {item.tip} ({item.tipPosude})
                </p>
              </div>

              <div>
                <p className="text-sm text-black text-gray-400">{item.datum}</p>
                <p className="text-gray-400 text-xxs">{item.vrijeme}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <table className="w-full text-sm font-bold table-fixed lg:w-5/6 lg:min-w-650 mb-50">
          <thead>
            <tr className="text-left text-gray-300 text-xxs">
              <th className="pb-20 pl-30">Tip otpada</th>
              <th className="pb-20 pl-30">Datum</th>
              <th className="pb-20 pl-30">Tip usluge</th>
              <th className="pb-20 pl-30">Vrijeme</th>
              <th className="pb-20 pl-30">Litara</th>
            </tr>
          </thead>
          <tbody>
            {transformedData.map((item) => {
              return (
                <tr
                  className="text-xs border-gray-100 border-b-1"
                  key={`${item.datum}-${item.vrijeme}`}
                >
                  <td className="py-2">
                    <table>
                      <tbody>
                        <tr>
                          <td className="">
                            <div
                              className={`${
                                item.tip === 'Mješoviti otpad'
                                  ? 'bg-black'
                                  : item.tip === 'Papir i karton'
                                  ? 'bg-darkBlue'
                                  : 'bg-darkYellow'
                              } w-18 h-18 rounded-full mr-25`}
                            ></div>
                          </td>
                          <td>{item.tip}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td className="py-20 text-gray-400 pl-30">{item.datum}</td>
                  <td className="py-20 text-gray-400 pl-30">
                    {item.tipPosude}
                  </td>
                  <td className="py-20 text-gray-400 pl-30">{item.vrijeme}</td>
                  <td className="py-20 text-gray-400 pl-30">{item.volumen}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </LayoutComponent>
  );
}
