import React from 'react';
import { NavLink } from 'react-router-dom';
const primaryColor = '#43B0A4';

const SidebarItem = ({
  icon,
  title,
  subtitle,
  isActive,
  handleNav = undefined,
  handleOnClick = undefined,
  objectItem,
}) => {
  const Icon = icon;

  if (handleOnClick) {
    return (
      <div className="p-20 text-sm">
        <button
          onClick={handleOnClick}
          className={`flex items-center relative`}
        >
          <Icon
            fill={` ${isActive ? primaryColor : '#757F83'} `}
            className="mr-20"
          />
          <div className="text-left">
            <h1
              className={` ${
                isActive ? primaryColor : '#757F83'
              } font-primarySb`}
            >
              {title}
            </h1>
            <p className={`subtitle text-xxs text-gray-300 text-left`}>
              {subtitle}
            </p>
          </div>
        </button>
      </div>
    );
  }

  return (
    <div className="p-20 text-sm">
      <NavLink to={handleNav} className={`flex items-center relative `}>
        <Icon
          fill={` ${isActive ? primaryColor : '#757F83'} `}
          className="mr-20"
        />
        <div>
          <h1
            className={` text-${
              isActive ? 'primary' : 'gray-500'
            } font-primarySb`}
          >
            {title}
          </h1>
          <p className={`subtitle text-xxs text-gray-300`}>{subtitle}</p>
        </div>
      </NavLink>
    </div>
  );
};

export default SidebarItem;
