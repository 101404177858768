export function ApiClient(endpoint, { body, ...customConfig } = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  return window
    .fetch(
      `${
        process.env.NODE_ENV === 'production'
          ? 'https://212.91.125.30:444/Api_Peovica/api'
          : 'https://212.91.125.30:444/Api_Peovica/api'
      }/${endpoint}`,
      config
    )
    .then(async (response) => {
      if (response.status === 401) {
        logout();
        return;
      }
      const data = await response.json();
      if (data === null) {
        location.href = '/account/login';
        logout();
        return;
      }
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}
function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('locations');
  localStorage.removeItem('selectedLocation');
}
