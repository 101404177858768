import React, { useEffect, useRef, useState } from 'react';
import ModalPlugin from 'react-modal';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';

import { CustomScrollbars } from '../CustomScrollbar';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

export const Modal = ({
  children,
  isOpen,
  onRequestClose,
  headerTitle,
  large = false,
}) => {
  const [scrollableMaxHeight, setScrollableMaxHeight] = useState('');

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const content = React.Children.map(children, (child) => {
    if (child.key === null) {
      return React.cloneElement(child);
    }
  });
  const footer = React.Children.map(children, (child) => {
    if (child.key && child.key.includes('Footer')) {
      return React.cloneElement(child);
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ModalPlugin.setAppElement('#root');
      window.addEventListener('resize', () =>
        setScrollableMaxHeight(getScrollableMaxHeight())
      );
    }
  }, []);

  const hasHeader = headerTitle;
  const hasFooter = footer && footer.length > 0;

  const getScrollableMaxHeight = () => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const base = isMobile ? 'calc(100vh - 40px)' : 'calc(100vh - 60px) ';
    const headerOffset =
      (headerRef.current ? headerRef.current.clientHeight : null || 0) + 'px';
    const footerOffset =
      (footerRef.current ? footerRef.current.clientHeight : null || 0) + 'px';

    return `calc(${base} - ${footerOffset} - ${headerOffset})`;
  };

  const afterOpenModal = () => {
    setScrollableMaxHeight(getScrollableMaxHeight());
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalPlugin
          overlayClassName={`bg-gray-500 overflow-y-hidden bg-opacity-50 justify-center items-end md:items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none m-auto`}
          className={`flex justify-center w-full h-full bg-transparent pointer-events-none outline-none items-center `}
          onRequestClose={onRequestClose}
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
        >
          <motion.div
            className={`${
              large ? 'md:w-650' : 'md:w-480'
            }  pointer-events-auto rounded-tr rounded-tl relative bg-white block outline-none md:rounded ${
              window.matchMedia('(max-width: 768px)').matches
                ? ' self-end w-full'
                : ''
            } `}
            key="modal"
            transition={{ duration: 0.2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div ref={headerRef}>
              <Header
                headerText={headerTitle}
                onRequestClose={onRequestClose}
              />
            </div>

            <CustomScrollbars autoHeightMax={scrollableMaxHeight}>
              <div className="pt-0 pr-40 p-30 md:p-60 md:pt-20 md:pb-20">
                {content}
              </div>
            </CustomScrollbars>
            {hasFooter && (
              <>
                <hr className="" />
                <div ref={footerRef} className={`px-30 md:px-60 py-25`}>
                  {footer}
                </div>
              </>
            )}
          </motion.div>
        </ModalPlugin>
      )}
    </AnimatePresence>
  );
};

Modal.Footer = Footer;

export default Modal;
