import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import Calendar from 'react-calendar';

import LayoutComponent from '../../components/commons/LayoutComponent';
import { SelectChart } from './../../components/SelectChart';
import { CalendarDots } from './../../components/CalendarDots';

import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';

import { getKalendarOdvoznji } from '../../services/property.service';
import { mjeseci, selectOptionsMjeseci } from '../Dashboard/Kante';
import Legend from '../Dashboard/components/Charts/Legend';

import useWindowSize from '../../hooks/useWindowSize';

const KalendarOdvoznji = () => {
  const currMonth = new Date().getMonth() + 1;
  const currYear = new Date().getFullYear();

  const isBreakpoint = useWindowSize('(max-width: 390px)');

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [selectMonthOptions, setSelectMonthOptions] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [date, setDate] = useState(new Date());

  const { data: odvoziPodaci, isLoading: isLoading } = useQuery(
    [`kalendarOdvoza`],
    getKalendarOdvoznji
  );

  const filterMonths = () => {
    return selectOptionsMjeseci.filter((monthOptions) => {
      return (
        new Date(`${year}/${monthOptions.value}/1`) >=
        new Date(`${currYear}/${currMonth}/1`)
      );
    });
  };

  const selectYears = (numOfYears) => {
    return Array(numOfYears)
      .fill(1)
      .map((_, i) => {
        return { value: currYear + i, label: currYear + i };
      });
  };

  useEffect(() => {
    setSelectMonthOptions(filterMonths());
  }, [month, year]);

  return (
    <LayoutComponent
      title={`Pregled kalendara odvožnje`}
      icon={<BackIcon fill="#C5C9CB" />}
    >
      <div className="flex flex-col items-center w-full p-0 mt-20 md:mt-0 max-w-520 md:p-30">
        <div
          className={`${
            isBreakpoint ? 'flex-col' : 'flex-row'
          } flex justify-between w-full mb-15`}
        >
          <div className="z-20 flex items-center self-start ">
            <CalendarIcon className="mr-10" />
            <SelectChart
              className="mr-8 w-95 "
              name="Mjesec"
              options={selectMonthOptions}
              value={{
                value: month,
                label: mjeseci[month],
              }}
              onChange={(e) => {
                setDate(new Date(`${year}/${e}/1`));
                setMonth(e);
              }}
            />
            <SelectChart
              className=" w-70"
              name="Godina"
              options={odvoziPodaci && selectYears(odvoziPodaci.brojGodina)}
              value={{
                value: year.toString(),
                label: year.toString(),
              }}
              onChange={(e) => {
                if (new Date(`${e}/${month}/1`) <= new Date()) {
                  setDate(new Date());
                  setYear(e);
                  setMonth(currMonth);
                  return;
                }
                setDate(new Date(`${e}/${month}/1`));
                setYear(e);
              }}
            />
          </div>
          <Legend className={`${isBreakpoint ? ' ml-25' : 'ml-auto'}`} />
        </div>
        <Calendar
          value={date}
          activeStartDate={date}
          showNavigation={false}
          tileContent={(data) => {
            return (
              <CalendarDots
                date={data.date}
                obj={odvoziPodaci ? odvoziPodaci.kalendarOdvoza : {}}
              />
            );
          }}
          formatShortWeekday={(locale, value) =>
            ['NED', 'PON', 'UTO', 'SRI', 'ČET', 'PET', 'SUB'][value.getDay()]
          }
        />
      </div>
    </LayoutComponent>
  );
};

export default KalendarOdvoznji;
