import React from 'react';
import { ReactComponent as Logo } from '../../../assets/icons/logo-white.svg';

export default function AuthHeader({ isMobile, navigate }) {
  return (
    <header
      className={`bg-primary w-full py-30 px-60 md:h-115 flex items-center bg-primary sm:py-25 sm:px-30 justify-between`}
    >
      <Logo />
      {isMobile && (
        <button onClick={navigate} className="flex-col flex ml-10 text-white">
          <span>Natrag</span>
        </button>
      )}
    </header>
  );
}
