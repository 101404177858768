import React, { useEffect, useRef } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export function useScrollLock(enabled = true, ref) {
  const disabledRef = useRef(false);
  useEffect(() => {
    if (enabled && !disabledRef.current) {
      disablePageScroll(ref ? ref.current : undefined);
      disabledRef.current = true;
    }
    return () => {
      if (disabledRef.current) {
        enablePageScroll(ref ? ref.current : undefined);
        disabledRef.current = false;
      }
    };
  }, [enabled, ref, disabledRef]);
}
