import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import Kante from './Kante';
import Kartice from './Kartice';
import LayoutComponent from '../../components/commons/LayoutComponent';
import DashboardNavigation from './components/DashboardNavigation';
import { getDatePreviousMonth } from '../../utils';

import { getGodisnjaStatistika } from '../../services/property.service';

const initialDate = getDatePreviousMonth(new Date());

const Dashboard = () => {
  const [dateValue, setDateValue] = useState(initialDate);

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: odvoziGodisnji, isLoading: isLoadingOdvoziGod } = useQuery(
    [`odvozi_godisnji_dashboard`, { year: dateValue.getFullYear() }],
    () => getGodisnjaStatistika(dateValue.getFullYear())
  );

  const handleNavigation = (tabInd) => {
    setActiveIndex(tabInd);
  };

  useEffect(() => {
    if (odvoziGodisnji) {
      if (
        odvoziGodisnji.mjesovitiKanta +
          odvoziGodisnji.papirKartonKanta +
          odvoziGodisnji.ambalazaKanta +
          odvoziGodisnji.stakloKanta >
          0 ||
        (odvoziGodisnji.mjesovitiKanta +
          odvoziGodisnji.papirKartonKanta +
          odvoziGodisnji.ambalazaKanta +
          odvoziGodisnji.stakloKanta ===
          0 &&
          odvoziGodisnji.mjesovitiKartica +
            odvoziGodisnji.papirKartonKartica +
            odvoziGodisnji.ambalazaKartica +
            odvoziGodisnji.stakloKartica ===
            0)
      ) {
        setActiveIndex(0);
      } else {
        setActiveIndex(1);
      }
    }
  }, [odvoziGodisnji]);

  return (
    <LayoutComponent title="Potrošnja">
      {isLoadingOdvoziGod ? (
        <div className="w-full h-40 mt-10 border-gray-100 border-b-1 lg:w-2/3 lg:min-w-650"></div>
      ) : (
        <>
          <DashboardNavigation
            handleNavigation={handleNavigation}
            activeIndex={activeIndex}
            kante={
              odvoziGodisnji.mjesovitiKanta +
                odvoziGodisnji.papirKartonKanta +
                odvoziGodisnji.ambalazaKanta +
                odvoziGodisnji.stakloKanta >
              0
            }
            kartice={
              odvoziGodisnji.mjesovitiKartica +
                odvoziGodisnji.papirKartonKartica +
                odvoziGodisnji.ambalazaKartica +
                odvoziGodisnji.stakloKartica >
              0
            }
          />
          {activeIndex && !isLoadingOdvoziGod ? (
            <Kartice
              isFirstLoad={isFirstLoad}
              setIsFirstLoad={setIsFirstLoad}
              year={dateValue.getFullYear()}
              month={dateValue.getMonth() + 1}
              dateValue={dateValue}
              setDateValue={setDateValue}
              odvoziGodisnji={odvoziGodisnji}
              isLoadingOdvoziGod={isLoadingOdvoziGod}
            />
          ) : (
            <Kante
              isFirstLoad={isFirstLoad}
              setIsFirstLoad={setIsFirstLoad}
              year={dateValue.getFullYear()}
              month={dateValue.getMonth() + 1}
              dateValue={dateValue}
              setDateValue={setDateValue}
              odvoziGodisnji={odvoziGodisnji}
              isLoadingOdvoziGod={isLoadingOdvoziGod}
            />
          )}
        </>
      )}
    </LayoutComponent>
  );
};

export default Dashboard;
