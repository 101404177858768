import React from 'react';
import Loader from '../Loader';

const Button = ({
  className,
  variant,
  isLoading,
  onClick,
  children,
  disabled,
}) => {
  if (variant === 'primary') {
    return (
      <button
        disabled={disabled}
        type="submit"
        onClick={onClick}
        className={` ${
          disabled ? 'cursor-not-allowed bg-primaryDisable' : 'bg-primary'
        } text-white w-full p-15 px-20 text-sm rounded-md  outline-none flex justify-center items-center ${className}`}
      >
        {isLoading ? <Loader /> : children}
      </button>
    );
  }
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`flex items-center justify-center w-full p-15 text-sm text-gray-300 bg-gray-100 rounded-md outline-none  ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
