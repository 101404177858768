import React, { useState, useEffect } from 'react';
import Checkbox from '../Form/components/Checkbox';
import Button from '../Button';
import Modal from '../Modal/index';

import styles from '../SelectMonthForm/SelectMonthForm.module.scss';

const currentYear = new Date().getFullYear();

const SelectYearForm = ({
  onRequestClose,
  handleYearChange,
  selectedYear,
  ...modalOptions
}) => {
  const [active, setActive] = useState(selectedYear);
  const handleChange = (e, index) => {
    setActive(index);
  };
  const handleSubmit = () => {
    handleYearChange(active);
    onRequestClose();
  };

  const renderYearCheckboxes = () => {
    const checkboxes = [];
    for (let i = 2018; i <= currentYear; i++) {
      checkboxes.push(i);
    }

    return checkboxes.map((year) => (
      <Checkbox
        key={year}
        name={String(year)}
        labelText={String(year)}
        onChange={(e) => handleChange(e, year)}
        checked={active === year}
      />
    ));
  };

  useEffect(() => {
    setActive(selectedYear);
  }, [selectedYear]);

  return (
    <Modal {...modalOptions} onRequestClose={onRequestClose}>
      <>{renderYearCheckboxes()}</>

      <Modal.Footer key="ModalFooter">
        <Button
          className="mb-20"
          type="submit"
          variant="primary"
          onClick={handleSubmit}
        >
          Potvrdi
        </Button>
        <Button type="button" onClick={onRequestClose} variant="">
          Poništi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectYearForm;
