import React, { useEffect, useState } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';
import ObjectDetailsForm from '../../components/ObjectDetailsForm';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
  getIzjavaKorisnika,
  getZahtjeviKorisnika,
} from '../../services/property.service';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-icon.svg';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ObjectDetailsModal from '../../components/ObjectDetailsModal';
import Status from '../../components/Status/Status';
import { getQueryParams } from '../../utils';

const parseZahtjev = (zahtjev) => {
  const transformed = {};
  zahtjev.Opis.replace(/\r/g, '')
    .split(/\n/)
    .filter((line) => line !== '')
    .forEach((line) =>
      Object.assign(transformed, { [line.split(':')[0]]: line.split(':')[1] })
    );
  Object.assign(transformed, { ...zahtjev });
  return transformed;
};

const parseZahtjevi = (zahtjevi) =>
  zahtjevi.map((zahtjev) => parseZahtjev(zahtjev));

const ObjectDetails = () => {
  const { search } = useLocation();
  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [detailsModal, setDetailsModal] = useState(false);
  const [newZahtjevModal, setNewZahtjevModal] = useState(false);

  const sifraObjekta = getQueryParams('code', search);

  const { id: idObjekta } = useParams();
  const { data: izjava, error: errorIzjava } = useQuery(
    `izjava_${idObjekta}`,
    async () => getIzjavaKorisnika(idObjekta)
  );
  const { data: zahtjevi, error: errorZahtjevi } = useQuery(
    `zahtjevi`,
    async () => getZahtjeviKorisnika(idObjekta)
  );

  useEffect(() => {
    if (izjava && zahtjevi) {
      setData([izjava, ...parseZahtjevi(zahtjevi)]);
    }
  }, [izjava, zahtjevi]);

  return (
    <LayoutComponent
      title={`OBJEKT ${sifraObjekta} - Izjave`}
      icon={<BackIcon fill="#C5C9CB" />}
    >
      <div className="flex flex-col mt-20 md:mt-0 md:flex-row md:w-full xl:w-2/3 xl:min-w-650">
        <div className="fixed z-10 lg:absolute top-85 lg:bottom-auto lg:top-30 right-40 md:right-60">
          <Button
            variant="primary"
            height="55"
            width="200"
            onClick={() => setNewZahtjevModal(true)}
          >
            Novi zahtjev
          </Button>
        </div>
        {errorIzjava || errorZahtjevi ? (
          <h2>Došlo je do greške </h2>
        ) : !data ? (
          <Loader color="primary" center />
        ) : data.length === 0 ? (
          <p className="py-20 min-w-sidebar">Nema podataka</p>
        ) : (
          <table className="w-full text-sm font-bold table-fixed max-w-650 mb-50">
            <thead>
              <tr className="text-left text-gray-300 text-xxs">
                <th colSpan={2} className="">
                  Datum
                </th>
                <th colSpan={2} className="">
                  Status
                </th>
                <th colSpan={1} className="">
                  Šifra objekta
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((zapis, index) => {
                return (
                  <tr
                    key={`${zapis.Datum || zapis.DatumIzjave}-${index}`}
                    className="text-xs border-gray-100 border-b-1"
                  >
                    <td colSpan={2} className="py-20 min-w-sidebar">
                      {zapis.DatumIzjave || zapis.Datum}
                    </td>
                    <td
                      colSpan={2}
                      // align="center"
                      className="py-20 text-gray-400"
                    >
                      {zapis.Oib ? (
                        <Status status={'Odobren'} />
                      ) : (
                        <Status status={zapis.Status} />
                      )}
                    </td>
                    <td colSpan={1} className="py-20 text-gray-400 ">
                      {sifraObjekta}
                    </td>
                    <td colSpan={1} align="center">
                      <button
                        className="flex text-gray-200 text-xxs"
                        onClick={() => {
                          setSelectedItem(zapis);
                          setDetailsModal(!detailsModal);
                        }}
                      >
                        Detalji
                        <ArrowDown
                          fill="#C5C9CB"
                          className="transform -rotate-90"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <ObjectDetailsModal
        isOpen={detailsModal}
        data={selectedItem}
        onRequestClose={() => setDetailsModal(false)}
        headerTitle="Detalji o objektu"
        large
      />
      <ObjectDetailsForm
        isOpen={newZahtjevModal}
        onRequestClose={() => setNewZahtjevModal(false)}
        headerTitle="Novi zahtjev"
        large
      />
    </LayoutComponent>
  );
};

export default ObjectDetails;
