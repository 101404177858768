import React from 'react';

export const OtpadVrste = ({ text, number }) => {
  const color = {
    'Mješoviti otpad': 'bg-gray-400 text-white',
    'Papir i karton': 'bg-darkBlue text-white',
    'Ambalažni otpad': 'bg-darkYellow',
    'Stakleni otpad': 'bg-green-400 text-white',
  };

  return (
    <div
      className={`${number <= 0 ? 'hidden' : ''} flex flex-col items-center`}
    >
      <div
        className={`flex mb-5 items-center justify-center w-40 h-40 rounded-full ${color[text]}`}
      >
        <p className="font-bold">{number}</p>
      </div>
      <p className="text-center text-xxs">{text}</p>
    </div>
  );
};
