import React from 'react';
import { toast, Slide } from 'react-toastify';

const ToastHeader = ({ text }) => {
  return <h3 className="mb-5">{text}</h3>;
};

const ToastContent = ({ text }) => {
  return (
    <div>
      <p>{text}</p>
    </div>
  );
};
const CloseButton = ({ closeToast }) => (
  <span className="text-red text-sm" onClick={closeToast}>
    x
  </span>
);

export const useToast = () => {
  const show = ({ headerText, message, type = 'success', options }) => {
    const Content = () => (
      <>
        <ToastHeader text={headerText} />
        <ToastContent text={message} />
      </>
    );

    const defaultOptions = {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      transition: Slide,
      className: 'flex w-full sm:h-60 md:h-40 px-20',
      bodyClassName: 'text-left text-sm',
      closeButton: CloseButton,
    };

    return toast[type](<Content />, { ...defaultOptions, ...options });
  };
  return show;
};
