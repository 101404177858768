import React from 'react';

import Button from '../../components/Button';
import Modal from '../Modal/index';

const ObjectDetailsModal = ({ data, onRequestClose, ...modalProps }) => {
  return (
    <Modal onRequestClose={onRequestClose} {...modalProps}>
      <>
        {data && (
          <div className="flex flex-col">
            <p className="text-gray-200 text-xxs py-30">
              Datum izjave: {data.Datum || data.DatumIzjave}
            </p>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">Prezime i ime </p>
              <h1 className="text-right">{data.ImePrezimeKorisnika}</h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Adresa korisnika javne usluge
              </p>
              <h1 className="text-right">
                {data.Ulica || data.AdresaKorisnika}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                E-mail adresa za dostavu računa
              </p>
              <h1 className="text-right">{data['E-mail']}</h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                OIB korisnika javne usluge
              </p>
              <h1>{data.Oib}</h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">Mjesto primopredaje</p>
              <h1 className="text-right">{data.MjestoPrimopredaje}</h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Broj članova domaćinstva
              </p>
              <h1 className="text-right">
                {data.BrojClanova || data['Broj članova domaćinstva']}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">Površina nekretnine</p>
              <h1>{data.PovrsinaNekretnine}</h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Broj prijavljenih turističkih ležajeva
              </p>
              <h1 className="text-right">
                {data.BrojTuristickihPostelja ||
                  data['Broj prijavljenih turističkih ležajeva']}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Udio u korišenju spremnika
              </p>
              <h1 className="text-right">
                {data.UdioKoristenjaSpremnika || 'Nepoznato'}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Količina i volumen spremnika
              </p>
              <h1 className="text-right">
                {data.KolicinaVolumenSpremnika || 'Nepoznato'}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Očitovanje o kompostiranju bio otpada u domaćinstvu
              </p>
              <h1 className="text-right">
                {data.KoristenjeKompostera || data['Kućna kompostana']}
              </h1>
            </div>
            {data.KoristenjeKompostera !== 'NE' &&
              data['Kućna kompostana'] !== ' NE' && (
                <div className="flex justify-between pb-20">
                  <p className="pr-8 text-xs text-gray-300">
                    Način kompostiranja
                  </p>
                  <h1></h1>
                </div>
              )}
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Broj planiranih primopredaja mješanog komunalnog otpada
              </p>
              <h1>
                {data.BrojPlaniranihOdvozaMijesani ||
                  data[
                    'Broj planiranih primopredaja mješanog komunalnog otpada'
                  ]}
              </h1>
            </div>
            <div className="flex justify-between pb-20">
              <p className="pr-8 text-xs text-gray-300">
                Broj planiranih primopredaja biorazgradivog otpada (papir,
                karton...){' '}
              </p>
              <h1>
                {data.BrojPlaniranihOdvozaBio ||
                  data[
                    'Broj planiranih primopredaja biorazgradivog otpada (papir, karton...)'
                  ]}
              </h1>
            </div>
          </div>
        )}
      </>
      <Modal.Footer large key="ModalFooter">
        <Button type="button" variant="" onClick={onRequestClose}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ObjectDetailsModal;
