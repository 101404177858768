import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import SidebarItem from '../SidebarItem';
import Content from '../Content';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Modal from '../../Modal';
import ChangeAddressForm from '../../ChangeAddressForm';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home-icon.svg';
import { ReactComponent as ChartIcon } from '../../../assets/icons/pie-chart-icon.svg';
import { ReactComponent as ReceiptIcon } from '../../../assets/icons/receipt-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout-icon.svg';
import { ReactComponent as Close } from '../../../assets/icons/close-icon.svg';
import { ReactComponent as LogoGray } from '../../../assets/icons/logo-gray.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building-icon.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { AnimatePresence, motion } from 'framer-motion';
import { useUser } from '../../../providers/User.provider';
import { useScrollLock } from '../../../hooks/useScrollLock';
import { capitalize } from '../../../utils';

const routes = ['/lokacije', '/', '/racuni', '/korisnicki-racun', '/objekti'];

const LayoutComponent = ({ children, title, icon = null }) => {
  const [activeIndex, setActiveIndex] = useState();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isChangeAddressModalOpened, setIsChangeAddressModalOpened] = useState(
    false
  );
  const { handleLogout } = useUser();
  const { user } = useUser();

  const {
    locations: objekti,
    handleSelectLocation,
    selectedLocation,
  } = useUser();

  const isMobile = useWindowSize('(max-width: 768px)');

  const history = useHistory();

  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  const toggleChangeAddressModal = () => {
    setIsChangeAddressModalOpened(!isChangeAddressModalOpened);
    setSidebarVisible(false);
  };

  const ref = useRef(null);
  useScrollLock(isChangeAddressModalOpened, ref);

  const logout = () => {
    handleLogout();
    location.href = '/login';
  };

  const onYearChange = (year) => {
    setYear(year);
  };

  useEffect(() => {
    const mainRoute = '/' + history.location.pathname.split('/')[1];
    setActiveIndex(routes.findIndex((route) => route === mainRoute));
  }, [history.location.pathname]);

  return (
    <div className="flex lg:flex-row md:flex-row sm:flex-col">
      {isMobile && (
        <>
          <Header
            isMobile={isMobile}
            toggleSidebar={toggleSidebar}
            isVisible={sidebarVisible}
          />
          <AnimatePresence>
            {sidebarVisible && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                className={`fixed top-0 max-h-screen min-h-screen left-0 w-full h-full overflow-hidden overflow-y-auto bg-gray-100 z-40`}
              >
                <motion.div
                  animate={{ x: 0 }}
                  exit={{ x: 360 }}
                  initial={{ x: 360 }}
                  transition={{ ease: 'easeIn' }}
                  className="flex flex-col items-start bg-gray-100 md:w-sidebar md:shadow-lg"
                >
                  <header
                    className={`bg-gray-100 w-full py-30 px-60 md:h-115 flex items-center bg-primary sm:py-25 sm:px-30 justify-between`}
                  >
                    <LogoGray />
                    <button onClick={toggleSidebar}>
                      <Close fill="gray" />
                    </button>
                  </header>

                  <Sidebar>
                    <div className="p-30 md:py-40 md:px-60">
                      <p className="text-gray-300 uppercase text-xxs">
                        Odabrani objekt:
                      </p>

                      <button
                        className="block w-full p-20 mt-10 mb-40 text-sm text-left bg-white rounded"
                        onClick={toggleChangeAddressModal}
                      >
                        <div className="relative flex items-center">
                          <HomeIcon fill="#757F83" />
                          <div className="flex flex-col px-20">
                            {selectedLocation && (
                              <>
                                <h1 className="font-primarySb">
                                  {selectedLocation &&
                                    capitalize(selectedLocation.Adresa.Ulica) +
                                      ' ' +
                                      selectedLocation.Adresa.KucniBroj}
                                </h1>
                                <p
                                  className={`subtitle text-xxs text-gray-300`}
                                >
                                  Šifra: {selectedLocation.SifraObejkta}
                                </p>
                              </>
                            )}

                            <ArrowDown
                              fill="#43B0A4"
                              height="35"
                              width="35"
                              className="absolute right-0 transform -rotate-90 -translate-y-1/2 top-50"
                            />
                          </div>
                        </div>
                      </button>
                      <SidebarItem
                        icon={ChartIcon}
                        title="Potrošnja"
                        subtitle="Pregled potrošnje"
                        isActive={activeIndex === 1}
                        handleNav="/"
                      />

                      <SidebarItem
                        icon={ReceiptIcon}
                        title="Računi"
                        subtitle="Pregled računa"
                        isActive={activeIndex === 2}
                        handleNav="/racuni"
                      />

                      <p className="text-gray-300 uppercase text-xxs mt-50">
                        Korisnik
                      </p>

                      <SidebarItem
                        icon={BuildingIcon}
                        title="Objekti"
                        subtitle="Pregled objekta"
                        isActive={activeIndex === 4}
                        handleNav="/objekti"
                      />

                      <SidebarItem
                        icon={LogoutIcon}
                        title="Odjavi se"
                        subtitle="Odjava iz korisničkog računa"
                        isActive={activeIndex === 5}
                        handleOnClick={logout}
                      />
                    </div>
                  </Sidebar>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
      <div className={`w-2/6 max-w-sidebar`}>
        {!isMobile && (
          <>
            <Header />
            <Sidebar>
              <div className="px-40 py-40">
                <p className="text-gray-300 uppercase text-xxs">
                  Odabrani objekt:
                </p>

                <button
                  className="block w-full p-20 mt-10 mb-40 text-sm text-left bg-white rounded"
                  onClick={toggleChangeAddressModal}
                >
                  <div className="relative flex items-center">
                    <HomeIcon fill="#757F83" />
                    <div className="flex flex-col px-20">
                      {selectedLocation && (
                        <>
                          <h1 className="font-primarySb">
                            {capitalize(selectedLocation.Adresa.Ulica) +
                              ' ' +
                              selectedLocation.Adresa.KucniBroj}
                          </h1>
                          <p className={`subtitle text-xxs text-gray-300`}>
                            Šifra: {selectedLocation.SifraObejkta}
                          </p>
                        </>
                      )}

                      <ArrowDown
                        fill="#43B0A4"
                        height="35"
                        width="35"
                        className="absolute right-0 transform -rotate-90 -translate-y-1/2 top-50"
                      />
                    </div>
                  </div>
                </button>

                <p className="text-gray-300 uppercase text-xxs">Pregled</p>

                <SidebarItem
                  icon={ChartIcon}
                  title="Potrošnja"
                  subtitle="Pregled potrošnje"
                  isActive={activeIndex === 1}
                  handleNav="/"
                />

                <SidebarItem
                  icon={ReceiptIcon}
                  title="Računi"
                  subtitle="Pregled računa"
                  isActive={activeIndex === 2}
                  handleNav="/racuni"
                />

                <p className="text-gray-300 uppercase text-xxs mt-50">
                  Korisnik
                </p>

                <SidebarItem
                  icon={BuildingIcon}
                  title="Objekti"
                  subtitle="Pregled objekta"
                  isActive={activeIndex === 4}
                  handleNav="/objekti"
                />

                <SidebarItem
                  icon={LogoutIcon}
                  title="Odjavi se"
                  subtitle="Odjava iz korisničkog računa"
                  isActive={activeIndex === 5}
                  handleOnClick={logout}
                />
              </div>
            </Sidebar>
          </>
        )}
      </div>
      <Content title={title} icon={icon}>
        {children}
      </Content>
      {/* isChangeAddressModalOpened */}
      {isChangeAddressModalOpened && (
        <ChangeAddressForm
          data={objekti}
          handleAddressChange={handleSelectLocation}
          isOpen={isChangeAddressModalOpened}
          onRequestClose={toggleChangeAddressModal}
          headerTitle="Promijeni adresu"
          selectedAddress={selectedLocation}
        />
      )}
    </div>
  );
};

export default LayoutComponent;
