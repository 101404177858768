import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FieldInput from '../Form/components/FieldInput';
import styles from './RequestAccountForm.module.scss';
import Button from '../Button';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../providers/User.provider';
const RASchema = Yup.object().shape({
  username: Yup.string().required('Obavezno polje'),
  email: Yup.string().email().required('Obavezno polje'),
});
const RequestAccountForm = ({}) => {
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(RASchema),
    mode: 'onChange',
  });
  const history = useHistory();
  const { handleRequestAccount } = useUser();
  const onSubmit = (data) => {
    handleRequestAccount(data.username, data.email);
  };
  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:mb-50 md:mb-0 w-full lg:w-480"
      >
        <FieldInput
          register={register}
          name="username"
          defaultValue=""
          type="text"
          errors={errors}
          labelText="Korisničko ime"
          value={watch('username')}
        />
        <FieldInput
          register={register}
          name="email"
          defaultValue=""
          type="email"
          errors={errors}
          labelText="Email"
          value={watch('email')}
        />
        <div className={`${styles.ButtonWrapper} mt-20 sm:mb-30 md:mb-0`}>
          <Button variant="primary">Zatraži</Button>
        </div>
        <hr className="border-gray-100 my-50 sm:hidden md:block"></hr>
      </form>
      <div className="font-secondary text-gray-200 text-xs static bottom-40 mt-70 flex justify-between md:hidden">
        <span>Peovica d.o.o 2020.</span>
        <span>MikroGoran + Locastic</span>
      </div>
    </>
  );
};
export default RequestAccountForm;
