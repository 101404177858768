import React from 'react';
import { ReactComponent as Logo } from '../../../assets/icons/logo-white.svg';
import { ReactComponent as Ham } from '../../../assets/icons/hamburger-icon.svg';

export default function Header({ isMobile = false, toggleSidebar }) {
  return (
    <header
      className={`bg-primary w-full py-30 px-60 md:h-115 flex items-center sm:py-25 sm:px-30 justify-between`}
    >
      <Logo />
      {isMobile && (
        <button onClick={toggleSidebar}>
          <Ham fill="white" />
        </button>
      )}
    </header>
  );
}
