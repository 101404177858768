import {
  OdvoziDto,
  GodisnjiOdvoziDto,
  SljedeciOdvoziDto,
  reciklaznoDvoristeDto,
  ApiClient,
  getNextMonth,
} from '../utils';

async function getIdObjekta() {
  return await JSON.parse(localStorage.getItem('selectedLocation')).Id;
}

function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

export const getOdvozi = async ({ year, month }) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();
  const res = await ApiClient(
    `OdvoziStatistika/${idObjekta}/${token}/json/${year}/${month}`
  );
  const parsed = await JSON.parse(res);
  if (parsed.Odvozi !== null) {
    if (Array.isArray(parsed.Odvozi.Odvoz)) {
      return OdvoziDto(parsed.Odvozi.Odvoz, month, year);
    }
    return OdvoziDto([parsed.Odvozi.Odvoz], month, year);
  }

  return null;
};

export const getMjesecnaStatistika = async ({ year, month }) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();
  const res = await ApiClient(
    `OdvoziStatistikaRazdoblje/${idObjekta}/${token}/json/${year}-${minTwoDigits(
      month
    )}-01/${month === 12 ? year + 1 : year}-${minTwoDigits(
      getNextMonth(parseInt(month))
    )}-01`
  );

  const parsed = await JSON.parse(res);

  if (parsed.Odvozi && parsed.Odvozi.Odvoz) {
    if (Array.isArray(parsed.Odvozi.Odvoz))
      return await OdvoziDto(parsed.Odvozi.Odvoz, month, year);
    else return await OdvoziDto([parsed.Odvozi.Odvoz], month, year);
  }
  return await OdvoziDto(parsed.Odvozi, month, year);
};

export const getGodisnjiOdvoz = async (year) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(
    `OdvoziStatistika/${idObjekta}/${token}/json/${year}`
  );

  return await GodisnjiOdvoziDto(JSON.parse(res).Odvozi.Odvoz);
};

export const getPosljednjiOdvoz = async ({ type = '' }) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(`Odvozi/${idObjekta}/${token}/json/${type}`);

  const parsed = await JSON.parse(res);

  if (parsed.Odvozi.Odvoz.length)
    return parsed.Odvozi.Odvoz[parsed.Odvozi.Odvoz.length - 1];

  return parsed.Odvozi.Odvoz;
};

export const getSljedeciOdvoz = async ({ type = '' }) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(
    `sljedecaodvoznja/${idObjekta}/${token}/json/${type}`
  );

  const parsed = await JSON.parse(res);

  return parsed.Kalendar.SljedecaOdvoznja;
};

const adjustDate = (date) => {
  // const splitDate = `${date.slice(0,2)}.${date.slice(2,2)}.${date.slice(0,2)}.`;
  return date.split('.').join('. ').trim();
};

export const getKalendarOdvoznji = async ({ type = '' }) => {
  const currYear = new Date().getFullYear();

  const token = JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(
    `kalendarodvoza/${idObjekta}/${token}/json/${type}`
  );

  const parsed = JSON.parse(res);

  const arr = SljedeciOdvoziDto(parsed.Kalendar.KalendarOdvoza);

  return arr;
};

export const getGodisnjaStatistika = async (year) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(
    `OdvoziStatistikaRazdoblje/${idObjekta}/${token}/json/${year}-01-01/${year}-12-31`
  );

  const parsed = JSON.parse(res);

  if (!parsed.Odvozi) {
    return await GodisnjiOdvoziDto(parsed.Odvozi);
  }

  return await GodisnjiOdvoziDto(parsed.Odvozi.Odvoz);
};

export const getRacuniByYear = async (year) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const idObjekta = await getIdObjekta();

  const res = await ApiClient(`Racuni/${idObjekta}/${token}/json/${year}`);
  const parsed = await JSON.parse(res).Racuni;

  if (!parsed) {
    return [];
  }

  if (parsed.Racun.length > 0) {
    return parsed.Racun;
  }
  return [parsed.Racun];
};
export const getRacunDetails = async (id) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const res = await ApiClient(`odabraniracun/${id}/${token}/json`);

  return await JSON.parse(res).Racun;
};

export const getRacunBase64 = async (racunId) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const res = await ApiClient(`pregledracuna/${racunId}/${token}/json`);

  return await JSON.parse(res).Racun.Base64;
};

export const getPodaciOReciklaznomDvoristu = async (mj, god) => {
  const id = await JSON.parse(localStorage.getItem('user')).Id;
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const res = await ApiClient(`reciklaznodvoriste/${id}/${token}/json`);
  const parsed = await JSON.parse(res);
  return reciklaznoDvoristeDto(parsed, mj, god);
};

export const getIzjavaKorisnika = async (idObjekta) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const res = await ApiClient(`izjavekorisnika/${idObjekta}/${token}/json`);
  const parsed = await JSON.parse(res);

  return await JSON.parse(res).IzjaveKorisnika.Izjava;
};
export const getZahtjeviKorisnika = async (idObjekta) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const id = await JSON.parse(localStorage.getItem('user')).Id;
  const res = await ApiClient(`zahtjevikorisnika/${id}/${token}/json`);
  const parsed = await JSON.parse(res);

  if (!parsed.ZahtjeviKorisnika) return [];

  let zahtjevi;

  if (!parsed.ZahtjeviKorisnika.Zahtjev.length) {
    zahtjevi = [parsed.ZahtjeviKorisnika.Zahtjev];
  } else {
    zahtjevi = [...parsed.ZahtjeviKorisnika.Zahtjev];
  }
  return zahtjevi.filter((z) => z.IdObjekta === idObjekta);
};

export const submitNewZahtjev = async (data) => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const id = await JSON.parse(localStorage.getItem('user')).Id;
  return await ApiClient(`unoszahtjeva/${id}/${token}/json`, {
    body: data,
  });
};
