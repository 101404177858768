import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import Listing from './components/Listing';
import { useQuery } from 'react-query';
import { getRacuniByYear } from '../../services/property.service';

const currentYear = new Date().getFullYear();

export default function Invoices() {
  const { data: racuniTrenutna } = useQuery([`racuni_${currentYear}`], () => {
    return getRacuniByYear(currentYear);
  });

  const { data: racuniPrethodna } = useQuery(
    [`racuni_${currentYear - 1}`],
    () => {
      return getRacuniByYear(currentYear - 1);
    }
  );

  return (
    <LayoutComponent title="Računi">
      <Listing data={racuniTrenutna} initialExpanded title={currentYear} />
      <Listing data={racuniPrethodna} title={currentYear - 1} />
    </LayoutComponent>
  );
}
