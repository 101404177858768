import React, { useState } from 'react';

import Button from '../../components/Button';
import Checkbox from '../../components/Form/components/Checkbox';
import Modal from '../Modal/index';

import { capitalize } from '../../utils';

import styles from '../SelectMonthForm/SelectMonthForm.module.scss';

const ChangeAddressForm = ({
  onRequestClose,
  data,
  handleAddressChange,
  selectedAddress,
  ...modalOptions
}) => {
  if (!data) return <></>;

  const [active, setActive] = useState(selectedAddress.Id);
  const handleChange = (id) => {
    setActive(id);
  };

  const handleSubmit = () => {
    handleAddressChange(active);
    window.location.reload();
    onRequestClose();
  };

  return (
    <Modal {...modalOptions} onRequestClose={onRequestClose}>
      <>
        {data.map((objekt) => (
          <Checkbox
            key={objekt.Id}
            labelText={
              capitalize(objekt.Adresa.Ulica) + ' ' + objekt.Adresa.KucniBroj
            }
            subtitleText={
              objekt.Mjesto.PostanskiBroj +
              ' ' +
              capitalize(objekt.Mjesto.Mjesto)
            }
            onChange={() => {
              handleChange(objekt.Id);
            }}
            checked={active === objekt.Id}
          />
        ))}
      </>

      <Modal.Footer key="ModalFooter">
        <Button
          className="mb-20"
          type="submit"
          variant="primary"
          onClick={handleSubmit}
        >
          Potvrdi
        </Button>
        <Button className="" type="button" onClick={onRequestClose} variant="">
          Poništi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAddressForm;
