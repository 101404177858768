import React from 'react';
import { motion } from 'framer-motion';

export default function ThreeDotsWave({ color = 'white', center }) {
  const loadingContainer = {
    width: '2rem',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-around',
    margin: center ? '0 auto' : '0 0',
  };

  const loadingCircle = {
    display: 'block',
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: color === 'white' ? '#FFFFFF' : '#4EBFB2',
    borderRadius: '0.25rem',
  };

  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const loadingCircleVariants = {
    start: {
      y: '50%',
    },
    end: {
      y: '150%',
    },
  };

  const loadingCircleTransition = {
    duration: 0.5,
    yoyo: Infinity,
    ease: 'easeInOut',
  };

  return (
    <motion.div
      style={loadingContainer}
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <motion.span
        style={loadingCircle}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        style={loadingCircle}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        style={loadingCircle}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
    </motion.div>
  );
}
