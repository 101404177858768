import React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import './Rechart.scss';

export default function YearChart({
  data,
  handleBarClick,
  type = 'KANTA',
  racuni,
}) {
  return (
    <ResponsiveContainer
      height={170}
      width="100%"
      className="YearResponsiveContainer"
    >
      <BarChart
        data={data}
        className="overflow-x-hidden overflow-y-hidden md:overflow-x-hidden"
      >
        <YAxis hide tickCount={2} />
        <XAxis interval={0} dataKey="mjesec" stroke="#C5C9CB" />
        <Tooltip cursor={{ fill: '#F2F4F4', radius: 5 }} active />
        <Bar
          dataKey={type === 'KANTA' ? 'mjesovitiKanta' : 'mjesovitiKartica'}
          name="Mješoviti otpad"
          fill="#51585C"
          width={15}
          radius={[20, 20, 0, 0]}
          onClick={(e) => handleBarClick(e)}
        ></Bar>
        {type === 'KANTA' && (
          <Bar
            dataKey="papirKartonKanta"
            name="Papir i karton"
            fill="#317AD1"
            width={15}
            radius={[20, 20, 0, 0]}
            onClick={(e) => handleBarClick(e)}
          ></Bar>
        )}
        <Bar
          dataKey={type === 'KANTA' ? 'ambalazaKanta' : 'ambalazaKartica'}
          name="Ambalažni otpad"
          fill="#F8B348"
          width={15}
          radius={[20, 20, 0, 0]}
          onClick={(e) => handleBarClick(e)}
        ></Bar>
        <Bar
          dataKey={type === 'KANTA' ? 'stakloKanta' : 'stakloKartica'}
          name="Stakleni otpad"
          fill="#439122"
          width={15}
          radius={[20, 20, 0, 0]}
          onClick={(e) => handleBarClick(e)}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
