import React from 'react';
import Content from '../Content';
import AuthSidebar from '../AuthSidebar';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/back-arrow.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { NavLink, useHistory } from 'react-router-dom';
import AuthHeader from '../AuthHeader';
import { useUser } from '../../../providers/User.provider';
import { ReactComponent as BackIcon } from '../../../assets/icons/back-arrow.svg';

export default function AuthLayout({ children, title }) {
  const isMobile = useWindowSize('(max-width: 768px)');
  const history = useHistory();
  const { location } = history;

  const handleHeaderBack = () => {
    if (location.pathname === '/login')
      return (window.location = 'https://peovica.hr/');
    return history.push('/login');
  };

  const renderLink = () => {
    if (location.pathname === '/request-account') {
      return (
        <NavLink to="/login">
          <p className="p-20 text-sm text-gray-500 flex justify-center items-center">
            <ArrowIcon fill="gray" />
            <div className="flex-col flex ml-20">
              <span>Natrag</span>
              <span className="text-xxs text-gray-300">
                Vrati se na prijavu
              </span>
            </div>
          </p>
        </NavLink>
      );
    }
    return (
      <a
        href="https://peovica.hr/"
        className="p-20 text-sm text-gray-500 flex justify-center items-center"
      >
        <ArrowIcon fill="gray" />
        <div className="flex-col flex ml-20">
          <span>Natrag</span>
          <span className="text-xxs text-gray-300">Vrati se na peovica.hr</span>
        </div>
      </a>
    );
  };

  return (
    <div className="flex flex-col ">
      <AuthHeader isMobile={isMobile} navigate={handleHeaderBack} />
      <div className="flex">
        {!isMobile && (
          <div className="w-2/6">
            <AuthSidebar>
              <div>
                {renderLink()}
                <div className="font-secondary text-gray-200 text-xs static bottom-40 mt-70 flex justify-between md:hidden">
                  <span>Peovica d.o.o 2020.</span>
                  <span>MikroGoran + Locastic</span>
                </div>
              </div>
            </AuthSidebar>
          </div>
        )}
        <Content title={title}>{children}</Content>
      </div>
    </div>
  );
}
