export const maxGodisnjiOdvozKanta = (data) => {
  let max = 0;
  if (data) {
    data.forEach((item) => {
      max = Math.max(max, item.mjesovitiKanta, item.papirKartonKanta);
    });
  }

  return max;
};
