import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Calendar from 'react-calendar';

import Loader from '../../components/Loader';
import { SelectChart } from './../../components/SelectChart';
import Legend from './components/Charts/Legend.js';
import { OtpadVrste } from '../../components/OtpadVrste';
import { CalendarDots } from './../../components/CalendarDots';
import MonthDetails from '../../components/MonthDetails';
import SelectYearForm from '../../components/SelectYearForm';
import YearChart from './components/Charts/YearChart';
import YAxisComponent from './components/Charts/YAxis';

import {
  getRacuniByYear,
  getMjesecnaStatistika,
  getPosljednjiOdvoz,
  getPodaciOReciklaznomDvoristu,
} from '../../services/property.service';
import {
  maxGodisnjiOdvozKartica,
  getDatePreviousMonth,
  capitalize,
  parseDate,
  getYearOptions,
} from '../../utils';
import { useScrollLock } from '../../hooks/useScrollLock.js';
import useWindowSize from '../../hooks/useWindowSize';

import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as RecycleIcon } from '../../assets/icons/recycle-icon.svg';

const mjeseci = {
  1: 'Siječanj',
  2: 'Veljača',
  3: 'Ožujak',
  4: 'Travanj',
  5: 'Svibanj',
  6: 'Lipanj',
  7: 'Srpanj',
  8: 'Kolovoz',
  9: 'Rujan',
  10: 'Listopad',
  11: 'Studeni',
  12: 'Prosinac',
};

const selectOptionsMjeseci = [
  { value: '1', label: 'Siječanj' },
  { value: '2', label: 'Veljača' },
  { value: '3', label: 'Ožujak' },
  { value: '4', label: 'Travanj' },
  { value: '5', label: 'Svibanj' },
  { value: '6', label: 'Lipanj' },
  { value: '7', label: 'Srpanj' },
  { value: '8', label: 'Kolovoz' },
  { value: '9', label: 'Rujan' },
  { value: '10', label: 'Listopad' },
  { value: '11', label: 'Studeni' },
  { value: '12', label: 'Prosinac' },
];

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

export default function Kartice({
  year,
  month,
  dateValue,
  setDateValue,
  odvoziGodisnji,
}) {
  const isMobile = useWindowSize('(max-width: 768px)');
  const isBreakpoint = useWindowSize('(max-width: 390px)');

  const [selectedCalendarDate, setSelectedCalendarDate] = React.useState(
    dateValue
  );

  const getIsCurrentMonth = () => {
    return currentMonth + 1 === month && currentYear === year;
  };

  const [isYearModalOpen, setIsYearModalOpen] = useState(false);
  const [isMonthOverviewModalOpen, setIsMonthOverviewModalOpen] = useState(
    false
  );

  const [monthData, setMonthData] = useState();
  const [selectMonthOptions, setSelectMonthOptions] = useState();

  const { data: odvoziTekMjeKartice, isLoading: isLoadingTekMj } = useQuery(
    [
      'odvozi_tek_mj_kartice',
      {
        month,
        year,
      },
    ],
    async () =>
      await getMjesecnaStatistika({
        month,
        year,
      })
  );
  const { data: odvoziPretMjeKartice, isLoading: isLoadingPretMj } = useQuery(
    [
      'odvozi_pret_mj_kartice',
      {
        month: getDatePreviousMonth(dateValue).getMonth() + 1,
        year: getDatePreviousMonth(dateValue).getFullYear(),
      },
    ],
    async () =>
      await getMjesecnaStatistika({
        month: getDatePreviousMonth(dateValue).getMonth() + 1,
        year: getDatePreviousMonth(dateValue).getFullYear(),
      })
  );

  const { data: posljednjiOdvoz, isLoading: isLoadingPosljednji } = useQuery(
    [`popisOdvoza`],
    getPosljednjiOdvoz
  );

  const { data: racuni, isLoading: isLoadingRacuni } = useQuery(
    [`racuni`, { year }],
    () => {
      return getRacuniByYear(year);
    }
  );

  const { data: reciklaznoDvoriste, isLoading: isLoadingRecDvo } = useQuery(
    ['reciklazno_dvoriste', month, year],
    () => getPodaciOReciklaznomDvoristu(month, year)
  );

  const ukupnoReciklirano = useCallback(() => {
    if (reciklaznoDvoriste && reciklaznoDvoriste.length > 0) {
      let ukupno = 0;
      reciklaznoDvoriste.forEach((item) => {
        ukupno += parseFloat(item.Kolicina);
      });
      return ukupno;
    }
    return 0;
  }, [reciklaznoDvoriste]);

  const toggleYearModal = () => {
    setIsYearModalOpen(!isYearModalOpen);
  };

  const toggleBarClick = (e) => {
    if (!racuni || isLoadingRacuni) {
      return () => {};
    }
    setIsMonthOverviewModalOpen(!isMonthOverviewModalOpen);
    setMonthData(e);
  };

  const ref = useRef(null);
  useScrollLock(isYearModalOpen, ref);
  useScrollLock(isMonthOverviewModalOpen, ref);

  const renderStats = () => {
    const odvoziLen = odvoziTekMjeKartice.kartice.length;
    const prevOdvoziLen =
      odvoziPretMjeKartice.kartice.length > 0
        ? odvoziPretMjeKartice.kartice.length
        : 1;

    if (odvoziLen > prevOdvoziLen) {
      const percentage =
        (Math.abs(odvoziLen - prevOdvoziLen) / prevOdvoziLen) * 100;
      return (
        <>
          <span className="inline text-red">{percentage.toFixed(2)}% više</span>{' '}
          korištenja kartice od prošlog mjeseca (
          {mjeseci[getDatePreviousMonth(dateValue).getMonth() + 1]})
        </>
      );
    }
    if (odvoziLen === prevOdvoziLen) {
      return (
        <>
          <span className="inline text-gray-400">
            Nema povećanja korištenja kartice
          </span>{' '}
          od prošlog mjeseca (
          {mjeseci[getDatePreviousMonth(dateValue).getMonth() + 1]})
        </>
      );
    }
    const percentage =
      (Math.abs(odvoziLen - prevOdvoziLen) / prevOdvoziLen) * 100;
    return (
      <>
        <span className="inline text-primary">
          {percentage.toFixed(2)}% manje
        </span>{' '}
        korištenja kartice od prošlog mjeseca (
        {mjeseci[getDatePreviousMonth(dateValue).getMonth() + 1]})
      </>
    );
  };

  const brojVrstaPoDanu = (obj, type) => {
    if (!obj) return 0;

    const dayData = obj[selectedCalendarDate.toLocaleDateString('HR-hr')];

    if (dayData) {
      if (type === 'mjes') return dayData.mjesoviti || 0;
      if (type === 'pik') return dayData.papirKarton || 0;
      if (type === 'amb') return dayData.ambalaza || 0;
      if (type === 'sta') return dayData.staklo || 0;
    }

    return 0;
  };

  const filterMonths = () => {
    return selectOptionsMjeseci.filter((monthOptions) => {
      return (
        new Date(`${year}/${monthOptions.value}/1`) <=
        new Date(`${currentYear}/${currentMonth}/1`)
      );
    });
  };

  const isLoading = useMemo(() => {
    return isLoadingPosljednji || isLoadingPretMj || isLoadingTekMj;
  }, [isLoadingPosljednji, isLoadingPretMj, isLoadingTekMj]);

  useEffect(() => {
    setSelectMonthOptions(filterMonths());
  }, [month, year]);

  if (isLoading) {
    if (isMobile) {
      return (
        <div className="absolute top-50 right-50">
          <Loader color="primary" center />
        </div>
      );
    } else {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <Loader color="primary" center />
        </div>
      );
    }
  }

  return (
    <>
      <div className="mt-20 border-2 border-gray-100 border-solid w-mobileFull md:w-full -ml-30 md:ml-0 xl:w-2/3 md:mt-55 md:rounded-lg xl:min-w-650">
        <div className="border-b-2 border-gray-100 ">
          <div className="flex flex-col w-full overflow-x-hidden overflow-y-hidden border-b-2 border-gray-100 lg:flex-row">
            <div className="flex flex-col items-center w-full p-20 border-gray-100 lg:border-r-2 md:p-30">
              <div
                className={`${
                  isBreakpoint ? 'flex-col' : 'flex-row'
                } flex justify-between w-full mb-25`}
              >
                <div className="z-20 flex items-center self-start">
                  <CalendarIcon className="mr-10" />
                  <SelectChart
                    className="mr-8 w-95 "
                    name="Mjesec"
                    options={selectMonthOptions}
                    value={{
                      value: month,
                      label: mjeseci[month],
                    }}
                    onChange={(e) => {
                      setDateValue(new Date(`${year}/${e}/1`));
                    }}
                  />
                  <SelectChart
                    className="w-70"
                    name="Godina"
                    options={getYearOptions(currentYear)}
                    value={{
                      value: year,
                      label: year,
                    }}
                    onChange={(e) => {
                      if (new Date(`${e}/${month}/1`) >= new Date()) {
                        setDateValue(new Date());
                        return;
                      }
                      setDateValue(new Date(`${e}/${month}/1`));
                    }}
                  />
                </div>
                <Legend
                  className={`${isBreakpoint ? ' ml-25' : 'ml-auto'}`}
                  showNum
                  type="KARTICE"
                  mjes={
                    odvoziTekMjeKartice &&
                    odvoziTekMjeKartice.mjesovitiKartica > 0
                      ? odvoziTekMjeKartice.mjesovitiKartica
                      : 0
                  }
                  amb={
                    odvoziTekMjeKartice &&
                    odvoziTekMjeKartice.ambalazaKartica > 0
                      ? odvoziTekMjeKartice.ambalazaKartica
                      : 0
                  }
                  sta={
                    odvoziTekMjeKartice && odvoziTekMjeKartice.stakloKartica > 0
                      ? odvoziTekMjeKartice.stakloKartica
                      : 0
                  }
                />
              </div>
              <Calendar
                onChange={(e) => {
                  setSelectedCalendarDate(e);
                }}
                onClickDay={(e) => {
                  setSelectedCalendarDate(e);
                }}
                value={dateValue}
                activeStartDate={dateValue}
                showNavigation={false}
                tileContent={(data) => {
                  return (
                    <CalendarDots
                      date={data.date}
                      obj={
                        odvoziTekMjeKartice
                          ? odvoziTekMjeKartice.karticeKalendar
                          : {}
                      }
                    />
                  );
                }}
                formatShortWeekday={(locale, value) =>
                  ['NED', 'PON', 'UTO', 'SRI', 'ČET', 'PET', 'SUB'][
                    value.getDay()
                  ]
                }
              />
              <div className="flex gap-20 mt-20 h-70">
                <OtpadVrste
                  number={
                    odvoziTekMjeKartice
                      ? brojVrstaPoDanu(
                          odvoziTekMjeKartice.karticeKalendar,
                          'mjes'
                        )
                      : 0
                  }
                  text="Mješoviti otpad"
                />
                <OtpadVrste
                  number={
                    odvoziTekMjeKartice
                      ? brojVrstaPoDanu(
                          odvoziTekMjeKartice.karticeKalendar,
                          'amb'
                        )
                      : 0
                  }
                  text="Ambalažni otpad"
                />
                <OtpadVrste
                  number={
                    odvoziTekMjeKartice
                      ? brojVrstaPoDanu(
                          odvoziTekMjeKartice.karticeKalendar,
                          'sta'
                        )
                      : 0
                  }
                  text="Stakleni otpad"
                />
              </div>
            </div>
            <div className="flex flex-row w-full border-t-2 border-gray-100 lg:flex-col lg:w-260 lg:border-t-0">
              <div className="flex flex-col w-1/2 p-20 border-r-2 border-gray-100 border-solid md:p-30 lg:w-full lg:border-r-0 lg:border-b-2">
                <p className="text-xs text-gray-300 md:text-base ">
                  Potrošnja po kartici
                </p>
                <h1 className="text-xl">
                  {(odvoziTekMjeKartice &&
                    odvoziTekMjeKartice.kartice.length) ||
                    '0'}
                </h1>
                <p className="text-gray-400 text-xxs md:pl-0 max-w-200 md:max-w-0">
                  {odvoziTekMjeKartice && odvoziPretMjeKartice && renderStats()}
                </p>
              </div>
              <div className="flex flex-col justify-between w-1/2 p-20 md:p-30 lg:w-full">
                <div className="flex items-center">
                  <RecycleIcon fill="#757F83" className="mr-20 lg:hidden" />
                  <p className="text-xs text-gray-300 md:text-base">
                    Reciklažno dvorište
                  </p>
                </div>
                {/* <h1 className="my-10 text-md">{0} KG</h1> */}
                <h1 className="text-md mt-30">{ukupnoReciklirano()} KG</h1>
                <p className="text-sm text-gray-300">Recikliranog otpada</p>
                {!getIsCurrentMonth() && racuni && racuni[month - 1] && (
                  <Link
                    to={`/racuni/${
                      racuni[month - 1].Id
                    }/pregled-otpada?m=${month}&y=${year}`}
                    className="self-start text-primary border-b-1 border-primary mt-30 text-xxs"
                  >
                    Vidi detalje
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center border-solid p-30 md:p-40 ">
              <p className="text-xs text-gray-300 md:text-base font-primarySb">
                Zadnja odvožnja
              </p>
              <h1 className="uppercase truncate text-md mt-30">
                {posljednjiOdvoz &&
                  parseDate(posljednjiOdvoz.DatumVrijeme).date.slice(0, 3)}
                {'.'}
              </h1>
              <p className="text-base text-center text-gray-300">
                {posljednjiOdvoz &&
                  parseDate(posljednjiOdvoz.DatumVrijeme).fullDate}
              </p>
              <p className="text-sm text-center text-gray-300">
                u{' '}
                {posljednjiOdvoz &&
                  parseDate(posljednjiOdvoz.DatumVrijeme).time}{' '}
                sati
              </p>
              {posljednjiOdvoz &&
              posljednjiOdvoz.VrstaOtpada === 'MIJEŠANI KOMUNALNI OTPAD' ? (
                <p className="mt-20 text-center text-gray-400 text-xxs">
                  Miješani komunalni otpad
                </p>
              ) : (
                <p className="mt-20 text-xxs text-darkBlue">Papir i karton</p>
              )}
            </div>
          </div>
        </div>
        <div className="p-40">
          <div className="w-full overflow-x-hidden overflow-y-hidden ">
            <div
              className={`${
                isBreakpoint ? 'flex-col' : 'flex-row items-center'
              } sticky left-0 flex justify-between  w-full mb-20`}
            >
              <div className="flex items-center mb-10">
                <CalendarIcon className="ml-0 md:ml-20" />
                <button onClick={toggleYearModal} className="flex items-center">
                  <h1 className="pr-5 ml-20 text-base font-primarySb">
                    {year}
                  </h1>
                  <ArrowDown fill="#43B0A4" />
                </button>
              </div>
              <Legend
                type="KARTICE"
                className={`${isBreakpoint ? ' ml-25' : 'ml-auto'}`}
              />
            </div>
            <div className="flex YearChart">
              <YAxisComponent
                y1={maxGodisnjiOdvozKartica(odvoziGodisnji.odvoziPoMjesecu) / 2}
                y2={maxGodisnjiOdvozKartica(odvoziGodisnji.odvoziPoMjesecu)}
              />
              <YearChart
                racuni={racuni}
                type="KARTICA"
                data={odvoziGodisnji.odvoziPoMjesecu}
                handleBarClick={toggleBarClick}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectYearForm
        selectedYear={year}
        handleYearChange={(e) => {
          if (new Date(`${e}/${month}/1`) >= new Date()) {
            setDateValue(new Date());
            return;
          }
          setDateValue(new Date(`${e}/${month}/1`));
        }}
        isOpen={isYearModalOpen}
        onRequestClose={toggleYearModal}
        headerTitle="Odaberi godinu"
      />
      <MonthDetails
        type="KARTICA"
        year={year}
        data={
          isMonthOverviewModalOpen
            ? {
                ...monthData,
                racun: monthData && racuni[monthData.mjIndex - 1],
              }
            : null
        }
        onRequestClose={toggleBarClick}
        isOpen={isMonthOverviewModalOpen}
        headerTitle={`${
          (monthData && monthData.mjFull && capitalize(monthData.mjFull)) || ''
        } ${year}.`}
      />
    </>
  );
}
