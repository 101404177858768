import { ApiClient } from '../utils';

export const login = async (username, password) => {
  return await ApiClient(`korisnik/${username}/${password}/json`);
};

export const requestAccount = async (oib, email) => {
  return await ApiClient(`korisnikzatraziracun/${oib}/${email}/json`);
};

export const confirmAccount = async (token) => {
  return await ApiClient(`korisnikpotvrdaregistracije/${token}/json`);
};

export const forgotPassword = async (username) => {
  return await ApiClient(`korisnikzaboravljenalozinka/${username}/json`);
};
