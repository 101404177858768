import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getPodaciOReciklaznomDvoristu } from '../../services/property.service';
import Loader from '../../components/Loader';

export default function Otpad() {
  const { search } = useLocation();

  const getQueryParams = (name) => {
    return new URLSearchParams(search).get(name);
  };
  const { data: reciklaznoDvoriste } = useQuery(
    ['reciklazno_dvoriste', getQueryParams('m'), getQueryParams('y')],
    () =>
      getPodaciOReciklaznomDvoristu(getQueryParams('m'), getQueryParams('y'))
  );

  return (
    <LayoutComponent
      title={`Pregled predanog otpada na reciklažnom dvorištu`}
      icon={<BackIcon fill="#C5C9CB" />}
    >
      {!reciklaznoDvoriste ? (
        <Loader color="primary" center />
      ) : reciklaznoDvoriste.length === 0 ? (
        <p className="py-20 min-w-sidebar">Nema podataka</p>
      ) : (
        <table className="w-full text-sm font-bold table-fixed max-w-650 mb-50">
          <thead>
            <tr className="text-left text-gray-300 text-xxs">
              <th className="">Vrsta otpada</th>
              <th className="pl-40">Datum i vrijeme</th>
              <th className="pl-40">Količina</th>
            </tr>
          </thead>

          <tbody>
            {reciklaznoDvoriste.map((item, index) => (
              <tr
                key={item.DatumVrijeme + index}
                className="text-xs border-gray-100 border-b-1"
              >
                <td className="py-20 min-w-sidebar">{item.VrstaOtpada}</td>

                <td className="py-20 text-gray-400 pl-30">
                  {item.DatumVrijeme}
                </td>
                <td className="py-20 text-gray-400 pl-30">
                  {item.Kolicina} {item.JedinicaMjere}{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </LayoutComponent>
  );
}
