import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Invoices from './pages/Invoices';
import Invoice from './pages/Invoice';
import Odvozi from './pages/Odvozi';
import Otpad from './pages/Otpad';
import Login from './pages/Login';
import KalendarOdvoznji from './pages/KalendarOdvoznji';
import Account from './pages/Account';
import Objects from './pages/Objects';
import ObjectDetails from './pages/ObjectDetails';
import RequestAccount from './pages/RequestAccount';
import { useUser } from './providers/User.provider';

export default function Router() {
  const { user } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (!user) history.push('/login');
    if (history.location.pathname === '/login' && user) history.replace('/');
  }, [user && user]);

  const renderRoutes = () => {
    if (user) {
      return (
        <>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/kalendar-odvoznji">
            <KalendarOdvoznji />
          </Route>
          <Route exact path="/racuni">
            <Invoices />
          </Route>
          <Route exact path="/racuni/:id">
            <Invoice />
          </Route>
          <Route exact path="/racuni/:id/pregled-odvoza">
            <Odvozi />
          </Route>
          <Route exact path="/racuni/:id/pregled-otpada">
            <Otpad />
          </Route>
          <Route exact path="/objekti">
            <Objects />
          </Route>
          <Route exact path="/korisnicki-racun">
            <Account />
          </Route>
          <Route exact path="/objekti/:id/detalji">
            <ObjectDetails />
          </Route>
          <Route exact path="/objekti/:id/izjava">
            <ObjectDetails />
          </Route>
        </>
      );
    }
    return (
      <>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/request-account">
          <RequestAccount />
        </Route>
      </>
    );
  };
  return <Switch>{renderRoutes()}</Switch>;
}
