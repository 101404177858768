import React, { useMemo, useState, useEffect, useCallback } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as InvoiceIcon } from '../../assets/icons/receipt-icon.svg';
import { ReactComponent as BarChartIcon } from '../../assets/icons/bar-chart-icon.svg';
import { ReactComponent as RecycleIcon } from '../../assets/icons/recycle-icon.svg';
import {
  getMjesecnaStatistika,
  getPodaciOReciklaznomDvoristu,
  getRacunBase64,
  getRacunDetails,
} from '../../services/property.service';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import { PercentageBar } from '../../components/PercentageBar';
import bwip from 'bwip-js';

const mjeseci = [
  'siječanj',
  'veljaču',
  'ožujak',
  'travanj',
  'svibanj',
  'lipanj',
  'srpanj',
  'kolovoz',
  'rujan',
  'listopad',
  'studeni',
  'prosinac',
];

export default function Invoice({}) {
  const { id } = useParams();
  const { search } = useLocation();

  const getQueryParams = (name) => {
    return new URLSearchParams(search).get(name);
  };

  const { data: racunDetalji } = useQuery([`racun_detalji_${id}`], () => {
    return getRacunDetails(id);
  });

  const mj = useMemo(() => {
    if (racunDetalji) {
      return parseInt(racunDetalji.DatumDokumenta.split('.')[1], 10);
    }
    return '';
  }, [racunDetalji]);

  const godina = useMemo(() => {
    if (racunDetalji) {
      return parseInt(racunDetalji.DatumDokumenta.split('.')[2], 10);
    }
    return '';
  }, [racunDetalji]);

  const handleGetOdvozi = async () => {
    if (mjesec && godina) {
      return await getMjesecnaStatistika({ year: godina, month: mj });
    }
  };
  const { data: odvozi } = useQuery(
    racunDetalji && `odvozi_racun_${id}`,
    handleGetOdvozi
  );

  const { data: racunBase64 } = useQuery('racun_base64', async () =>
    getRacunBase64(id)
  );

  const { data: reciklaznoDvoriste } = useQuery(
    ['reciklazno_dvoriste', mj, godina],
    () => getPodaciOReciklaznomDvoristu(mj, godina)
  );

  const ukupnoReciklirano = useCallback(() => {
    if (reciklaznoDvoriste && reciklaznoDvoriste.length > 0) {
      let ukupno = 0;
      reciklaznoDvoriste.forEach((item) => {
        ukupno += parseFloat(item.Kolicina);
      });
      return ukupno;
    }
    return 0;
  }, [reciklaznoDvoriste]);

  useEffect(() => {
    racunDetalji &&
      bwip.toCanvas('invoiceBarcode', {
        bcid: 'pdf417', // Barcode type
        text: racunDetalji.Barkod, // Text to encode
        scale: 3, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        width: 8,
        includetext: false, // Show human-readable text
        textxalign: 'center', // Always good to set this
      });
  }, [racunDetalji]);

  const mjesec = useMemo(() => {
    if (racunDetalji) {
      return mjeseci[
        parseInt(racunDetalji.DatumDokumenta.split('.')[1], 10) - 1
      ];
    }
    return '';
  }, [racunDetalji]);

  return (
    <LayoutComponent
      title={`Račun za ${mjesec}`}
      icon={<BackIcon fill="#C5C9CB" />}
    >
      <>
        {racunDetalji && (
          <div className="mt-20 border-2 border-gray-100 border-solid w-mobileFull md:w-full -ml-30 md:ml-0 xl:w-2/3 md:mt-55 md:rounded-lg xl:min-w-650">
            <div className="flex flex-col md:flex-row">
              <div className="w-full p-40 border-b-2 border-gray-100 border-solid lg:border-b-0 ">
                <div className="flex-row flex-col h-full overflow-x-hidden overflow-y-hidden ">
                  <div className="flex flex-col w-full">
                    <div className="flex">
                      <InvoiceIcon fill="#757F83" />

                      <h1 className="pr-5 ml-20 text-xs text-gray-400 md:text-base font-primarySb">
                        Iznos računa
                      </h1>
                    </div>
                    <div className="flex flex-col justify-between pt-20 lg:flex-row">
                      <div className="flex flex-col items-start justify-between md:items-start ">
                        <div>
                          <h1 className="pb-10 text-lg text-gray-500 font-primarySb">
                            {racunDetalji.UkupniIznos &&
                              racunDetalji.UkupniIznos}
                            <span className="uppercase text-md"> {godina <2023 ? 'kn' : '€'}</span>
                          </h1>

                          {getQueryParams('status') === 'true' ? (
                            <span className="inline px-10 py-5 text-center text-white bg-green-500 rounded-xs">
                              PLAĆENO
                            </span>
                          ) : (
                            <span className="inline px-10 py-5 text-center text-white bg-red rounded-xs">
                              NEPLAĆENO
                            </span>
                          )}
                        </div>
                        <a
                          className="text-primary border-b-1 border-primary mt-30 text-xxs"
                          href={`data:application/pdf;base64,${
                            racunBase64 || ''
                          }`}
                          download={`racun_${mjesec}.pdf`}
                        >
                          Preuzmi PDF
                        </a>
                      </div>
                      {/* <div className="flex flex-col"> */}
                      <div className="mt-30 lg:mt-0 lg:ml-auto ">
                        <canvas id="invoiceBarcode"></canvas>
                      </div>
                      {/* <Button height="55" width="240" variant="primary">
                          Plati online
                        </Button> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col border-gray-100 lg:flex-row lg:border-t-2">
              <div className="flex flex-col items-start w-full border-b-2 border-gray-100 border-solid p-30 md:p-40 lg:border-b-0 lg:w-1/2 lg:border-r-2">
                <div className="flex items-center pb-30">
                  <BarChartIcon fill="#757F83" className="mr-20" />
                  <p className="text-xs text-gray-400 md:text-base font-primarySb">
                    Odvozi/Kartica{' '}
                  </p>
                  <p className="pl-10 text-base text-gray-200">
                    {odvozi && odvozi.rawData.length}
                  </p>
                </div>
                <PercentageBar
                  ukupno={odvozi ? odvozi.rawData.length : 1}
                  kante={odvozi ? odvozi.odvozi.length : 0}
                  kartice={odvozi ? odvozi.kartice.length : 0}
                />
                <Link
                  to={`/racuni/${id}/pregled-odvoza`}
                  className="text-primary border-b-1 border-primary mt-30 text-xxs"
                >
                  Vidi detalje
                </Link>
              </div>
              <div className="flex flex-col items-start w-full p-30 md:p-40 lg:w-1/2">
                <div className="flex items-center">
                  <RecycleIcon fill="#757F83" className="mr-20" />
                  <p className="text-xs text-gray-400 md:text-base font-primarySb">
                    Reciklažno dvorište
                  </p>
                </div>
                {/* <h1 className="text-md mt-30">{0} KG</h1> */}
                <h1 className="text-md mt-30">{ukupnoReciklirano()} KG</h1>
                <p className="text-sm text-gray-300">Recikliranog otpada</p>
                <Link
                  to={`/racuni/${id}/pregled-otpada?m=${mj}&y=${godina}`}
                  className="text-primary border-b-1 border-primary mt-30 text-xxs"
                >
                  Vidi detalje
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    </LayoutComponent>
  );
}
