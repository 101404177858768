import React from 'react';
import RequestAccountForm from '../../components/RequestAccountForm';
import AuthLayout from '../../components/commons/AuthLayout';

export default function RequestAccount() {
  return (
    <AuthLayout title="Zatraži korisnički račun">
      <p
        className={`w-full md:w-315 text-sm text-gray-400 font-secondary mb-40`}
      >
        Niste obvezni unjeti podatke u polja. Ona služe zbog bolje pružanja
        usluge.
      </p>
      <RequestAccountForm />
    </AuthLayout>
  );
}
