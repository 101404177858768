import React from 'react';
import FieldInput from '../Form/components/FieldInput';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../providers/User.provider';

const AccountSchema = Yup.object().shape({
  email: Yup.string().email().required('Obavezno polje'),
  kontaktBroj: Yup.string().required('Obavezno polje'),
  adresa: Yup.string().required('Obavezno polje'),
});

const AccountForm = () => {
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(AccountSchema),
    mode: 'onChange',
  });
  const history = useHistory();
  const { user } = useUser();

  const onSubmit = (data) => {
    history.push('/');
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FieldInput
        labelText="Email"
        register={register}
        name="email"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('email')}
      />
      <FieldInput
        labelText="Kontakt broj"
        register={register}
        name="kontaktBroj"
        defaultValue=""
        type="phone"
        errors={errors}
        value={watch('kontaktBroj')}
      />
      <FieldInput
        labelText="Adresa dostave računa"
        register={register}
        name="adresa"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('adresa')}
      />
      <Button variant="primary" height="55" width="240" onClick={handleSubmit}>
        Spremi izmjene
      </Button>
    </form>
  );
};
export default AccountForm;
