import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Login from '../src/pages/Login';
import UserProvider from './providers/User.provider';
import Router from './router';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

import './styles/toast/main.scss';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-T98V382',
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router />
          <ToastContainer />
          <ReactQueryDevtools />
        </BrowserRouter>
      </QueryClientProvider>
    </UserProvider>
  );
}

export default App;
