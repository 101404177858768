export const dateFormat = (date) => {
  return `${date.split('.')[2]}/${date.split('.')[1]}/${date.split('.')[0]} ${
    date.split('.')[3]
  }`;
};

export const removeLeadingZerrow = (number) => {
  const numString = String(number);

  return number < 10 ? parseInt(numString.slice(1)) : parseInt(numString);
};
