import React from 'react';

const DashboardNavigation = ({
  handleNavigation,
  activeIndex,
  kante,
  kartice,
}) => {
  return (
    <div className="w-full mt-10 border-gray-100 border-b-1 lg:w-2/3 lg:min-w-650">
      {(kante || !(kante || kartice)) && (
        <button className="outline-none" onClick={() => handleNavigation(0)}>
          {activeIndex === 0 ? (
            <h1 className="py-5 text-sm mr-15 border-b-5 border-primary">
              Kante
            </h1>
          ) : (
            <h1 className="text-sm text-gray-200 mr-15">Kante</h1>
          )}
        </button>
      )}
      {kartice && (
        <button className="outline-none" onClick={() => handleNavigation(1)}>
          {activeIndex === 1 ? (
            <h1 className="py-5 text-sm border-b-5 border-primary ">Kartice</h1>
          ) : (
            <h1 className="text-sm text-gray-200">Kartice</h1>
          )}
        </button>
      )}
    </div>
  );
};

export default DashboardNavigation;
