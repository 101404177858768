import { ApiClient } from '../utils';

export const getObjekti = async () => {
  const token = await JSON.parse(localStorage.getItem('user')).Token;
  const id = await JSON.parse(localStorage.getItem('user')).Id;
  const res = await ApiClient(`objekti/${id}/${token}/json`);
  const parsed = await JSON.parse(res).ObjektiKorisnika.Objekt;
  if (Array.isArray(parsed)) return parsed;
  return [parsed];
};
