import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-icon.svg';
import { useUser } from '../../providers/User.provider';
import { capitalize } from '../../utils';
import Modal from '../../components/Modal';
import ObjectDetailsModal from '../../components/ObjectDetailsModal';

import useWindowSize from '../../hooks/useWindowSize';

const Objects = () => {
  const isSmallScreen = useWindowSize('(max-width: 450px)');

  const [isObjectDetailsModalOpen, setIsObjectDetailsModalOpen] = useState(
    false
  );
  const [modalData, setModalData] = useState();
  const { locations: objekti } = useUser();

  const toggleObjectDetailsModal = (objekt) => {
    setModalData(objekt);
    setIsObjectDetailsModalOpen(!isObjectDetailsModalOpen);
  };
  return (
    <LayoutComponent title="Objekti">
      {isSmallScreen ? (
        <div className="w-full mt-20">
          {objekti.map((objekt) => {
            return (
              <div className="flex items-center py-20 text-xs" key={objekt.Id}>
                <div className="w-2/5">
                  <h2 className="">
                    {capitalize(objekt.Adresa.Ulica).split('-')[1]}{' '}
                    {objekt.Adresa.KucniBroj}
                  </h2>
                  <p className="text-gray-400 ">
                    {capitalize(objekt.Adresa.Ulica.split(' ')[0])}
                  </p>
                </div>

                <div className="w-2/5 text-left">
                  <h2 className="">
                    {objekt.Mjesto.PostanskiBroj}{' '}
                    {capitalize(objekt.Mjesto.Mjesto)}
                  </h2>

                  <p className="text-gray-400 uppercase ">
                    {objekt.SifraObejkta}
                  </p>
                </div>

                <div className="w-1/5 text-right">
                  <Link
                    to={`/objekti/${objekt.Id}/detalji?code=${objekt.SifraObejkta}`}
                    className="flex text-gray-200 text-xxs"
                    onClick={() => toggleObjectDetailsModal(objekt)}
                  >
                    Izjava
                    <ArrowDown
                      fill="#C5C9CB"
                      className="transform -rotate-90"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <table className="w-full mt-20 text-sm font-bold table-fixed md:mt-0 max-w-650 mb-50">
          <thead>
            <tr className="text-left text-gray-300 text-xxs">
              <th className="">Ulica</th>
              <th className="">Mjesto</th>
              <th className="">Grad</th>
              <th className="">Šifra objekta</th>
            </tr>
          </thead>
          <tbody>
            {objekti.map((objekt) => (
              <tr
                key={objekt.Id}
                className="text-xs border-gray-100 border-b-1"
              >
                <td className="px-5 py-20 min-w-sidebar">
                  {capitalize(objekt.Adresa.Ulica).split('-')[1]}{' '}
                  {objekt.Adresa.KucniBroj}
                </td>
                <td className="px-5 py-20 text-gray-400">
                  {capitalize(objekt.Adresa.Ulica.split(' ')[0])}
                </td>
                <td className="px-5 py-20 text-gray-400">
                  {objekt.Mjesto.PostanskiBroj}{' '}
                  {capitalize(objekt.Mjesto.Mjesto)}
                </td>
                <td className="px-5 py-20 text-gray-400">
                  {objekt.SifraObejkta}
                </td>
                <td align="center">
                  <Link
                    to={`/objekti/${objekt.Id}/detalji?code=${objekt.SifraObejkta}`}
                    className="flex text-gray-200 text-xxs"
                    onClick={() => toggleObjectDetailsModal(objekt)}
                  >
                    Izjava
                    <ArrowDown
                      fill="#C5C9CB"
                      className="transform -rotate-90"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        isOpen={isObjectDetailsModalOpen}
        onRequestClose={toggleObjectDetailsModal}
        headerTitle="Detalji o objektu"
      >
        <ObjectDetailsModal
          onRequestClose={toggleObjectDetailsModal}
          data={modalData}
        />
      </Modal>
    </LayoutComponent>
  );
};

export default Objects;
