import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import AccountForm from '../../components/AccountForm';

const Account = () => {
  return (
    <LayoutComponent title="Podaci o korisniku">
      <div className="w-full md:w-480">
        <p className="pt-30 md:pt-0 text-gray-400 text-sm font-secondary pb-20">
          Niste obvezni unijeti podatke u polja. Ona služe zbog bolje pružanja
          usluge.
        </p>

        <AccountForm />
      </div>
    </LayoutComponent>
  );
};

export default Account;
